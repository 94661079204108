import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.button<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--color-primaryOne);
  border-radius: 1.2rem;
  cursor: pointer;
  height: 4.5rem;
  width: 4.5rem;
  border: none;

  ${({ customStyles }) => customStyles};
`
