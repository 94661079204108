import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__left {
      display: flex;
      align-items: center;

      h1 {
        font-weight: normal;
        margin: 0;
      }

      span {
        color: var(--color-grey);
        margin: auto 0 0 1.6rem;
        font-size: 1.4rem;
      }
    }

    .box__search {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 1.6rem;
    }
  }

  main {
    margin-top: 6.4rem;

    .title__list {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 1.6rem;

      font-size: 1.2rem;
      padding: 1.6rem;
    }

    ul {
      li {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 1.6rem;

        border: solid 0.1rem transparent;
        font-weight: normal;
        font-size: 1.2rem;
        padding: 1.6rem;

        span {
          display: flex;
          align-items: center;

          svg {
            color: var(--color-blue);
            margin: 0 0.4rem 0 0;
            height: 1.6rem;
            width: 1.6rem;
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        &:hover {
          border: solid 0.1rem var(--color-blue);
          border-radius: 0.8rem;
          background: #2f80ed10;
        }

        .centralized {
          margin: 0 auto;
        }
      }
    }
  }
`
