import { firebaseAuth } from '../../services/firebaseClient'
import { RefreshTokenFirebase } from '../../services/Session'

export async function refreshTokenFirebase(): Promise<void> {
  try {
    const { firebase_token } = (await RefreshTokenFirebase()) as { firebase_token: string }
    await firebaseAuth(firebase_token)
  } catch (error) {
    throw new Error('Firebase: ERROR REFRESH TOKEN FIREBASE')
  }
}
