import styled from 'styled-components'

export const Container = styled.div`
  background: linear-gradient(180deg, #fff 0%, #fffee1 20%, #fffadf 40%, #fff 100%);
  min-height: 100vh;

  .container {
    padding: 12.8rem 6.4rem 3.2rem 6.4rem;
  }

  .navMenu__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: var(--color-primaryOne);
    border-radius: 0 0 1.6rem 1.6rem;
    color: var(--color-white);

    .box__left {
      display: flex;

      .logo {
        margin: 1.4rem 1.4rem 1.4rem 3.2rem;

        img {
          height: 4rem;
        }
      }

      ul {
        display: flex;

        li {
          span,
          a {
            display: flex;
            align-items: center;

            color: var(--color-white);
            padding: 0 1.6rem;
            height: 7.4rem;

            &:hover {
              border-bottom: 0.4rem solid var(--color-primaryTwo);
              color: var(--color-primaryTwo);
              cursor: pointer;
              height: 7rem;
            }
          }

          .submenu {
            display: flex;

            background: var(--color-button);
            position: absolute;
            right: 0;
            left: 0;

            ul {
              display: flex;
              justify-content: center;

              a {
                height: 5rem;

                &:hover {
                  height: 4.6rem;
                }
              }
            }
          }
        }
      }
    }

    .box__right {
      display: flex;
      align-items: center;

      .divider {
        background: var(--color-grey);
        margin-right: 3.2rem;
        height: 3.2rem;
        width: 0.1rem;
      }

      .box__card {
        display: flex;
        align-items: center;

        margin-right: 3.2rem;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;

          background: var(--color-primaryTwo);
          margin-right: 0.8rem;
          border-radius: 1rem;
          height: 4rem;
          width: 4rem;
        }

        button {
          color: var(--color-white);
          background: transparent;
          cursor: pointer;
          border: none;
        }

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 12.8rem;
          overflow: hidden;
        }
      }
    }
  }
`
