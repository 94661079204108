import { IContacts } from '../../types'
import ApiClient from '../ApiClient'
import { IContactsPayload } from './interfaces'

const route = '/api/industries/contacts/'

const GetContacts = (industry_id: string): Promise<IContacts> => ApiClient.api.get(route + industry_id)

const PatchContacts = ({ industry_id, payload }: IContactsPayload): Promise<IContacts> =>
  ApiClient.api.patch(route + industry_id, payload)

export { PatchContacts, GetContacts }
