import React, { createContext, useState, useEffect } from 'react'

import { routesPublic } from '../routes/routesLink'
import ApiClient from '../services/ApiClient'
import { firebaseAuth } from '../services/firebaseClient'
import { SessionIndustry } from '../services/Session'
import { ILoginPayload } from '../services/Session/interfaces'
import { toastify } from '../utils'

import { IUser } from '../types'

interface IAuthenticateContext {
  handleSignIn({ password, email }: ILoginPayload): Promise<IUser | void>
  handleSignOut(): void
  user: IUser | null
  signed: boolean
}

interface IAuthProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext<IAuthenticateContext>({} as IAuthenticateContext)

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [signed, setSigned] = useState<boolean>(false)
  const [user, setUser] = useState<IUser | null>(null)

  async function handleSignIn({ email, password }: ILoginPayload) {
    try {
      const response = await SessionIndustry({ email, password })

      const { firebase_token, refresh_token, token, user, role } = response
      
      if (role === 'industry_master') {
        await firebaseAuth(firebase_token)

        localStorage.setItem('@tokens', JSON.stringify({ refresh_token, token }))
        localStorage.setItem('@user', JSON.stringify(user))

        ApiClient.setTokenInHeader(token)

        setSigned(true)
        setUser(user)
      } else {
        toastify('Apenas usuários com perfil de indústria!', 'error')
        handleSignOut()
      }
    } catch (error: any) {
      toastify(error?.response?.data?.message, 'error')
    }
  }

  function handleSignOut() {
    setSigned(false)
    setUser(null)

    window.location.href = routesPublic.login
    localStorage.clear()
  }

  function loadStorageData() {
    const tokensStorage = localStorage.getItem('@tokens')
    const userStorage = localStorage.getItem('@user')

    if (tokensStorage && userStorage) {
      const { token } = JSON.parse(tokensStorage)
      const user = JSON.parse(userStorage)

      ApiClient.setTokenInHeader(token)

      setSigned(true)
      setUser(user)
    }
  }

  useEffect(() => loadStorageData(), [])

  return (
    <AuthContext.Provider
      value={{
        handleSignOut,
        handleSignIn,
        signed,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
