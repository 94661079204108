import React from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'

import { Container } from './styles'

interface IItems {
  value: string | number
  text: string | number
}

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  customStyles?: React.CSSProperties
  suggestions: IItems[]
  iconSearch?: boolean
  label?: string

  register?: UseFormRegister<FieldValues>
  error?: FieldError
}

const Select: React.FC<IProps> = ({ customStyles, suggestions, register, error, label, ...rest }) => {
  return (
    <Container customStyles={customStyles}>
      {label}

      <div className="box__input">
        {register && rest.name ? (
          <select   {...register(rest.name)} {...rest}>
            <option value={undefined}></option>

            {suggestions?.map(item => (
              <option key={item.value} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        ) : (
          <select  {...rest}>
            <option value={undefined}></option>

            {suggestions?.map(item => (
              <option key={item.value} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        )}
      </div>

      <span>{!!error && error.message}</span>
    </Container>
  )
}

export { Select }
