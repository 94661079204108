import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;

    h1 {
      font-weight: normal;
      margin: 0;
    }
  }

  main {
    margin-top: 6.4rem;

    .box__details,
    .title__list {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 1.6rem;

      font-size: 1.2rem;
      padding: 1.6rem;
    }

    .box__details {
      padding: 0;

      span {
        display: flex;
        align-items: center;

        svg {
          margin: 0 0.4rem 0 0;
          height: 1.6rem;
          color: #f2c931;
          width: 1.6rem;
        }
      }
    }

    ul {
      li {
        display: grid;
        grid-template-columns: 12rem repeat(6, 1fr);
        grid-gap: 1.6rem;

        font-size: 1.4rem;

        img {
          border: 0.1rem solid var(--color-primaryTwo);
          border-radius: 0.8rem;
          object-fit: cover;
          padding: 1.6rem;
          height: 8.8rem;
          width: 8.8rem;
        }

        div {
          display: flex;
          justify-content: center;
          flex-direction: column;

          p {
            margin: 0;
          }
        }

        &:not(:first-child) {
          border-top: solid 0.1rem #21384810;
          padding-top: 1.6rem;
          margin-top: 1.6rem;
        }

        .margin-bottom {
          margin-bottom: 0.8rem;
        }

        .centralized {
          display: flex;
          align-items: center;
        }

        .fex-end {
          display: flex;
          align-items: end;
        }
      }
    }

    .box__address {
      display: flex;

      font-size: 1.4rem;

      .address {
        display: flex;
        flex-direction: column;

        margin-right: 6.4rem;
        min-width: 32rem;

        p {
          margin: 0.8rem 0 0 0;
        }
      }
    }
  }
`
