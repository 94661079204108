import styled from 'styled-components'

export const Container = styled.div`
  header {
    h1 {
      font-weight: normal;
      margin: 0;
    }

    .box__title {
      display: flex;
      justify-content: space-between;
    }

    .box__search {
      display: grid;
      grid-template-columns: 3fr repeat(3, 1fr);
      grid-gap: 1.6rem;

      margin: 3.2rem 0;
    }
  }

  main {
    .title__list {
      display: grid;
      grid-template-columns: 2fr repeat(9, 1fr);
      grid-gap: 1.6rem;

      font-size: 1.2rem;
      padding: 1.6rem;
    }

    ul {
      li {
        display: grid;
        grid-template-columns: 2fr repeat(9, 1fr);
        grid-gap: 1.6rem;

        border: solid 0.1rem transparent;
        font-weight: normal;
        font-size: 1.2rem;
        padding: 1.6rem;

        img {
          border-radius: 0.8rem;
          margin-right: 1.6rem;
          object-fit: cover;
          height: 4rem;
          width: 4rem;
        }

        span {
          display: flex;
          align-items: center;
        }

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        &:hover {
          border: solid 0.1rem var(--color-blue);
          border-radius: 0.8rem;
          background: #2f80ed10;
        }
      }
    }
  }
`
