import { ITitle } from '../../../components/Table'
import { formatMask } from '../../../utils'

const formatterTypeAccount = (type: string) => (type === 'checking_account' ? 'Conta corrente' : 'Conta poupança')

const formatterDocumentNumber = (number: string) =>
  number.length === 11 ? formatMask(number, '###.###.###-##') : formatMask(number, '##.###.###/####-##')

export const exampleTitleMainAccount: ITitle[] = [
  { title: 'Banco', attribute: 'bank_name' },
  { title: 'Agência', attribute: 'agency' },
  { title: 'Conta', attribute: 'account_number' },
  { title: 'CPF/CNPJ', attribute: 'document_number', formatter: formatterDocumentNumber },
  { title: 'Tipo de conta', attribute: 'type', formatter: formatterTypeAccount }
]
