import { AxiosResponse } from 'axios'

export function logResponse(response: AxiosResponse): void {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    console.group('Response OK')

    console.log(response.data)

    console.groupEnd()
  }
}
