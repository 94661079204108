import React, { useState } from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'

import { UilAngleDown } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.InputHTMLAttributes<any> {
  setState?: React.Dispatch<React.SetStateAction<any>>
  customStyles?: React.CSSProperties
  suggestions: ISuggestions[]
  label?: string

  register?: UseFormRegister<FieldValues>
  error?: FieldError
}

export interface ISuggestions {
  value: string
  text: string
}

const Autocomplete: React.FC<IProps> = ({ setState, customStyles, suggestions, register, error, label, ...rest }) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState<ISuggestions[]>([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [input, setInput] = useState<string>()

  function onChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    const userInput = target.value

    const unLinked = suggestions.filter(
      suggestion => suggestion.text.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    if (setState) setState(undefined)
    setFilteredSuggestions(unLinked)
    setActiveSuggestionIndex(0)
    setShowSuggestions(true)
    setInput(target.value)
  }

  function onClick({ target }: any) {
    if (setState) setState(target.id)

    setActiveSuggestionIndex(0)
    setFilteredSuggestions([])
    setInput(target.innerText)
    setShowSuggestions(false)
  }

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="box__suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className

          if (index === activeSuggestionIndex) className = 'suggestion-active'

          return (
            <li className={className} key={index} onClick={onClick} id={suggestion.value}>
              {suggestion.text}
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="box__no__suggestions">Sem sugestões!</div>
    )
  }

  return (
    <Container customStyles={customStyles}>
      {label}

      <div className="box__input">
        {register && rest.name ? (
          <input
            {...register(rest.name)}
            onChange={onChange}
            value={input || rest.value}
            {...rest}
          />
        ) : (
          <input
            onChange={onChange}
            value={input || rest.value}
            {...rest}
          />
        )}

        <UilAngleDown color="var(--color-secondaryOne)" />

        {showSuggestions && <SuggestionsListComponent />}
      </div>

      <span>{!!error && error.message}</span>
    </Container>
  )
}
export { Autocomplete }
