import React from 'react'
import { useNavigate } from 'react-router-dom'

import { UilAngleLeft } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.ButtonHTMLAttributes<void> {
  customStyles?: React.CSSProperties
}

const ButtonBack: React.FC<IProps> = ({ customStyles }) => {
  const navigate = useNavigate()

  return (
    <Container customStyles={customStyles} onClick={() => navigate(-1)} type="button">
      <UilAngleLeft color="white" />
    </Container>
  )
}

export { ButtonBack }
