import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
  active?: boolean
}

export const Container = styled.div<IContainerProps>`
  align-items: center;
  display: flex;

  p {
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    margin-left: 0.8rem;
  }

  div {
    display: flex;
    justify-content: flex-start;

    background-color: ${({ active }) => (active ? 'var(--color-blue)' : '#3D5B6F40')};
    justify-content: ${({ active }) => (active ? 'flex-end' : 'flex-start')};
    border-radius: 10rem;
    padding: 0.2rem;
    cursor: pointer;
    width: 4rem;

    .switch__span {
      border-radius: 100%;
      background: white;
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  ${({ customStyles }) => customStyles}
`
