import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  background: var(--color-primaryOne);
  height: 100vh;

  .box__left,
  .box__right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box__left {
    background: var(--color-primaryTwo);
    border-radius: 0 3.2rem 3.2rem 0;

    img {
      margin-bottom: 6.4rem;
      max-width: 50rem;
      width: 100%;
    }

    .divider {
      background: var(--color-primaryOne);
      max-width: 20rem;
      height: 0.1rem;
      width: 100%;
    }

    h1 {
      font-weight: lighter;
    }
  }

  .box__right {
    .container {
      max-width: 40rem;
      width: 100%;

      img {
        margin-bottom: 12.8rem;
        height: 12.8rem;
      }
    }
  }
`
