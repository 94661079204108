import React from 'react'

import { Autocomplete, Button, ButtonBack, Input } from '../../../components'
import { Container } from './styles'

const SettingsCommercialOpinion: React.FC = () => {
  return (
    <Container>
      <header>
        <ButtonBack />
        <h1>Parecer comercial</h1>
      </header>

      <main>
        <Input customStyles={{ marginBottom: '1.6rem' }} label="VENDEDOR" placeholder="Insira o nome do vendedor" />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="TABELA DE PREÇOS"
          placeholder="Insira o valor da tabela de preços"
        />

        <div className="divider" />

        <Input customStyles={{ marginBottom: '1.6rem' }} label="REDE NOVA" placeholder="Insira a rede nova" />

        <Input customStyles={{ marginBottom: '1.6rem' }} label="SIM - NOME" placeholder="" />

        <Input customStyles={{ marginBottom: '1.6rem' }} label="NÃO - NOME" placeholder="" />

        <div className="divider" />

        <Autocomplete
          customStyles={{ marginBottom: '1.6rem' }}
          label="MODALIDADE DE FRETE"
          placeholder="Selecione a modalidade de frete"
          suggestions={[]}
        />

        <Button customStyles={{ margin: '0 auto' }}>Salvar alterações</Button>
      </main>
    </Container>
  )
}

export { SettingsCommercialOpinion }
