export function formatPrice(value?: string | number): string {
  if (value === undefined || value === 0 || value === '0') return 'R$ 0,00'

  value = value.toString().replace(/\D/g, '')

  const string = value[0] === '0' ? value.substr(1) : value
  const length = value.length

  if (length <= 1) return `R$ ${string.replace(/([0-9]{1}$)/g, '0,0$1')}`
  if (length <= 2) return `R$ ${string.replace(/([0-9]{2}$)/g, '0,$1')}`
  if (length >= 3 && length < 6) return `R$ ${string.replace(/([0-9]{2}$)/g, ',$1')}`
  if (length >= 6 && length < 9) return `R$ ${string.replace(/([0-9]{1})([0-9]{3})([0-9]{2}$)/g, '$1.$2,$3')}`

  return `R$ ${string.replace(/([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{2}$)/g, '$1.$2.$3,$4')}`
}
