import ApiClient from '../ApiClient'
import {
  IProductsListResponse,
  IProductShowResponse,
  IProductsParams,
  IPayloadUpdate,
  IPayloadCreate
} from './interfaces'

const route = '/api/products/'

const ProductsList = (params: IProductsParams): Promise<IProductsListResponse> => ApiClient.api.get(route, { params })
const ProductsUpdate = ({ payload, id }: IPayloadUpdate): Promise<void> => ApiClient.api.patch(route + id, payload)
const ProductsCreate = (payload: IPayloadCreate): Promise<void> => ApiClient.api.post(route, payload)
const ProductsShow = (id: string): Promise<IProductShowResponse> => ApiClient.api.get(route + id)
const ProductsDelete = (id: string): Promise<void> => ApiClient.api.delete(route + id)

export { ProductsDelete, ProductsCreate, ProductsList, ProductsShow, ProductsUpdate }
