import React from 'react'

import { Autocomplete, Button, ButtonBack, Input } from '../../../components'
import { Container } from './styles'

const SettingsCommercialData: React.FC = () => {
  return (
    <Container>
      <header>
        <ButtonBack />
        <h1>Dados comerciais</h1>
      </header>

      <main>
        <h4>Região de entrega</h4>

        <Autocomplete label="SELECIONE UMA OU MAIS REGIÕES" placeholder="Selecione" suggestions={[]} />

        <div className="divider" />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="PRINCIPAL FORNECEDOR"
          placeholder="Nome do principal fornecedor"
        />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="MARCA PRINCIPAL PRODUTO"
          placeholder="Insira a marca do principal produto"
        />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="QNT. REPRESENTA NO FATURAM. DA EMPRESA"
          placeholder="Insira o valor que representa"
        />

        <div className="divider" />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="PRINCIPAL FORNECEDOR"
          placeholder="Nome do principal fornecedor"
        />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="MARCA PRINCIPAL PRODUTO"
          placeholder="Insira a marca do principal produto"
        />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="QNT. REPRESENTA NO FATURAM. DA EMPRESA"
          placeholder="Insira o valor que representa"
        />

        <div className="divider" />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="PRINCIPAL FORNECEDOR"
          placeholder="Nome do principal fornecedor"
        />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="MARCA PRINCIPAL PRODUTO"
          placeholder="Insira a marca do principal produto"
        />

        <Input
          customStyles={{ marginBottom: '1.6rem' }}
          label="QNT. REPRESENTA NO FATURAM. DA EMPRESA"
          placeholder="Insira o valor que representa"
        />

        <Button customStyles={{ margin: '0 auto' }}>Salvar alterações</Button>
      </main>
    </Container>
  )
}

export { SettingsCommercialData }
