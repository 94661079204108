import React from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import ilustraLogin from '../../assets/images/ilustraLogin.svg'
import logoVarejoAquiClear from '../../assets/images/logoVarejoAquiClear.svg'
import { Button, Input, InputPassword } from '../../components'
import { useAuth } from '../../hooks/useAuth'
import { Container } from './styles'

import { schemaLogin } from './schemaLogin'

const Login: React.FC = () => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(schemaLogin) })
  const { errors } = formState
  const { handleSignIn } = useAuth()

  function handleLogin({ password, email }: any) {
    handleSignIn({ password, email })
  }

  return (
    <Container>
      <div className="box__left">
        <div className="container">
          <img src={ilustraLogin} alt="" />

          <div className="divider" />

          <h1>
            Indústria e o Varejo a<br />
            <strong>um passo de distância.</strong>
          </h1>
        </div>
      </div>

      <form className="box__right" onSubmit={handleSubmit(handleLogin)}>
        <div className="container">
          <img src={logoVarejoAquiClear} alt="" />

          <Input
            customStyles={{ color: 'var(--color-primaryTwo)', marginBottom: '3.2rem' }}
            defaultValue={process.env.REACT_APP_USER_EMAIL}
            placeholder="Insira seu e-mail"
            label="E-MAIL"
            name="email"
            error={errors.email}
            register={register}
          />

          <InputPassword
            customStyles={{ color: 'var(--color-primaryTwo)' }}
            defaultValue={process.env.REACT_APP_USER_PASSWORD}
            placeholder="Insira sua senha"
            type="password"
            name="password"
            label="SENHA"
            error={errors.password}
            register={register}
          />

          <a
            href={process.env.REACT_APP_REDIRECT_API_CHANGE_PASSWORD}
            className="link"
            rel="noreferrer"
            target="_blank"
          >
            esqueci minha senha
          </a>

          <Button customStyles={{ width: '20rem', marginTop: '6.4rem', marginLeft: '50%' }} clearTemplate type="submit">
            Entrar
          </Button>
        </div>
      </form>
    </Container>
  )
}

export { Login }
