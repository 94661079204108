import { motion } from 'framer-motion'

import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled(motion.div)<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100vh;
  z-index: 200;
  width: 100vw;
  left: 0;
  top: 0;

  .modal {
    background: var(--color-white);
    border-radius: 1.4rem;
    padding: 1.6rem;

    /* height: max(50%, 30rem); */
    /* width: max(50%, 30rem); */

    header {
      display: flex;
      justify-content: space-between;

      margin-bottom: 3.2rem;
      position: relative;

      h4 {
        font-weight: normal;
        font-size: 2.4rem;
        margin: 0;
      }

      & > button {
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--color-secondaryOne);
        border-radius: 1rem;
        position: absolute;
        height: 4.5rem;
        right: -2.4rem;
        width: 4.5rem;
        border: none;
        top: -2.4rem;
        padding: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .children {
      ${({ customStyles }) => customStyles}
    }
  }
`
