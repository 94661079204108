import { ISegments } from '../../types'
import ApiClient from '../ApiClient'
import { ISegmentsUpdatePayload, ISegmentsIndexParams, IResponse, ISimpleListResponse, ISegmentsSimplesListParams, ISegmentSimpleList } from './interfaces'

const route = '/api/industry_segments/'

const SegmentsIndex = (params: ISegmentsIndexParams): Promise<IResponse> => ApiClient.api.get(route, { params })
const SegmentsSimpleList = (params?: ISegmentsSimplesListParams): Promise<ISegmentSimpleList[]> => ApiClient.api.get(route + 'simple_list', { params })
const SegmentsCreate = (payload: ISegments): Promise<void> => ApiClient.api.post(route, payload)

const SegmentsUpdate = ({ payload, segment_id }: ISegmentsUpdatePayload): Promise<void> =>
  ApiClient.api.patch(route + segment_id, payload)

export { SegmentsSimpleList, SegmentsUpdate, SegmentsCreate, SegmentsIndex }
