import { routesPrivate } from '../../routes/routesLink'

export const subMenuAnimate = {
  exit: { transition: { duration: 0.3, delay: 0.5 }, transitionEnd: { display: 'none' }, rotateX: -15, opacity: 0 },
  enter: { transition: { duration: 0.3 }, display: 'block', opacity: 1, rotateX: 0 }
}

export const navMenuLinks = [
  { title: 'Dashboard', path: routesPrivate.home },
  { title: 'Pedidos', path: routesPrivate.orders },
  {
    title: 'Catálogo',
    subMenus: [
      { title: 'Produtos', path: routesPrivate.products },
      { title: 'Marcas', path: routesPrivate.catalogBrands },
      { title: 'Segmentos', path: routesPrivate.catalogSegments }
    ]
  },
  { title: 'Varejistas', path: routesPrivate.retailers },
  { title: 'Configurações', path: routesPrivate.settings },
  { title: 'Financeiro', path: routesPrivate.financial },
  { title: 'Avaliações', path: routesPrivate.reviews }
]
