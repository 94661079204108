import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import dayjs from 'dayjs'

import { Button, CardBox, Input, Loading, Paginate } from '../../components'
import { routesPrivate } from '../../routes/routesLink'
import { GetOrdersList } from '../../services/Orders'
import { formatDate, formatPrice, toastify } from '../../utils'
import { Container } from './styles'

import { ORDER_STATUS } from '../../constants'

const REFETCH_INTERVAL = 30 * 1000

const Orders: React.FC = () => {
  const { isLoading, isFetched, data, refetch } = useQuery('orders', fetchData, { refetchInterval: REFETCH_INTERVAL })

  const [params, setParams] = useState({ pageOffset: 6, page: 1 })

  async function fetchData() {
    try {
      return await GetOrdersList(params)
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregar os pedidos', 'error')
    }
  }

  function handleChangeParams(event: any): void {
    const { value, name, type } = event.target

    if (type === 'date') {
      if (value === '') {
        setParams({ pageOffset: 6, page: 1 })
      } else {
        const date = dayjs(value).toISOString()
        setParams(prevState => ({ ...prevState, page: 1, [name]: value ? new Date(date) : undefined }))
      }
    } else {
      if (name === '') {
        setParams({ pageOffset: 6, page: 1 })
      } else {
        setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
      }
    }
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params])

  return (
    <Container>
      <header>
        <h1>Pedidos</h1>

        <div className="box__search">
          <Input
            placeholder="Busque pelo número do pedido..."
            onChange={handleChangeParams}
            name="order_number"
            iconSearch
          />

          <Input
            onChange={handleChangeParams}
            placeholder="Todas as datas"
            label="Data de criação"
            name="created_at"
            type="date"
          />

          <Input
            onChange={handleChangeParams}
            label="Estimativa de entrega"
            placeholder="Todas as datas"
            name="estimate_date"
            type="date"
          />

          <Button onClick={() => refetch()} customStyles={{ marginTop: '2.4rem' }}>
            Pesquisar
          </Button>
        </div>
      </header>

      <main>
        <div className="title__list">
          <span>Data</span>
          <span>Pedido</span>
          <span>Varejista</span>
          <span>Status</span>
          <span>Qnt. de produtos</span>
          <span>Data estimada de entrega</span>
          <span>Valor do pedido</span>
        </div>

        {isLoading && <Loading style={{ margin: '0 auto' }} />}

        {isFetched && (
          <>
            <CardBox customStyles={{ padding: 0 }}>
              <ul>
                {data?.results?.map(order => (
                  <li key={order?.orders_id}>
                    <span>{formatDate(order?.orders_created_at)}</span>
                    <span>{order?.orders_order_number}</span>
                    <span>{order?.retailer_company_name}</span>
                    <span>{ORDER_STATUS[order?.orders_status]}</span>
                    <span>{order?.items}</span>
                    <span className="centralized">{formatDate(order?.shipment_estimated_date)}</span>
                    <span>{formatPrice(order?.orders_total)}</span>

                    <Link className="link centralized" to={routesPrivate.ordersDetails + order?.orders_id}>
                      ver detalhes
                    </Link>
                  </li>
                ))}
              </ul>
            </CardBox>

            <Paginate
              customStyles={{ position: 'fixed', bottom: '1.6rem' }}
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              currentPage={params.page}
            />
          </>
        )}
      </main>
    </Container>
  )
}

export { Orders }
