import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')
dayjs.extend(utc)

class DateUtils {
  compareInHours(start_date: Date, end_date: Date): number {
    const start_date_utc = this.convertToUTC(start_date)
    const end_date_utc = this.convertToUTC(end_date)

    return dayjs(end_date_utc).diff(start_date_utc, 'hours')
  }

  compareInDays(start_date: Date, end_date: Date): number {
    const start_date_utc = this.convertToUTC(start_date)
    const end_date_utc = this.convertToUTC(end_date)

    return dayjs(end_date_utc).diff(start_date_utc, 'days')
  }

  translateDate(date: Date, format: string): string {
    return dayjs(date).locale('pt-br').format(format)
  }

  convertToUTC(date: Date): string {
    return dayjs(date).utc().local().format()
  }

  dateNow(): Date {
    return dayjs().toDate()
  }

  addDays(days: number): Date {
    return dayjs().add(days, 'days').toDate()
  }

  addHours(hours: number): Date {
    return dayjs().add(hours, 'hours').toDate()
  }

  compareIfBefore(start_date: Date, end_date: Date): boolean {
    return dayjs(start_date).isBefore(end_date)
  }
}

export const dateUtils = new DateUtils()
