import React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import { UilDollarSignAlt } from '@iconscout/react-unicons'

import * as Components from '../../components'
import { routesPrivate } from '../../routes/routesLink'
import * as financialService from '../../services/Financial'
import { GeneralSalesSummary } from '../../services/General'
import * as utils from '../../utils'
import { Container } from './styles'

import { PAYMENT_METHOD } from '../../constants'

const Financial: React.FC = () => {
  const { isLoading, isFetched, data } = useQuery('financial', fetchData)

  async function fetchData() {
    try {
      const retailer_avg_invoices = await financialService.GetRetailersMeanInvoices()
      const response = await financialService.GetAccumulatedInvoicing()
      const net_invoicing = await financialService.GetNetInvoicing()
      const rates_by_methods = await financialService.GetRates()
      const sales_summary = await GeneralSalesSummary()

      const accumulated_invoicing = response.map(({ total_invoiced, month }) => ({
        month: utils.dateUtils.translateDate(month, 'MMMM'),
        total_invoiced
      }))

      return { retailer_avg_invoices, accumulated_invoicing, rates_by_methods, sales_summary, net_invoicing }
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar carregar as estatísticas financeiras', 'error')
    }
  }

  return (
    <>
      {isLoading && <Components.Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <h1>Receita bruta</h1>

            <div style={{ display: 'flex' }}>
              <Link to={routesPrivate.orders}>
                <Components.Button style={{ marginRight: '1.6rem' }}>Pedidos</Components.Button>
              </Link>

              <Link to={routesPrivate.financialHistory}>
                <Components.Button>Ver histórico financeiro</Components.Button>
              </Link>
            </div>
          </header>

          <main>
            <section className="revenue__per__period">
              <Components.CardBox className="recipe__cards">
                <div className="recipe__cards-card">
                  <span>Hoje</span>
                  <p>{utils.formatPrice(data?.net_invoicing?.today_invoiced)}</p>
                </div>

                <UilDollarSignAlt />
              </Components.CardBox>

              <Components.CardBox className="recipe__cards">
                <div className="recipe__cards-card">
                  <span>Mês</span>
                  <p>{utils.formatPrice(data?.net_invoicing?.month_invoiced)}</p>
                </div>

                <UilDollarSignAlt />
              </Components.CardBox>

              <Components.CardBox className="recipe__cards">
                <div className="recipe__cards-card">
                  <span>Ano</span>
                  <p>{utils.formatPrice(data?.net_invoicing?.year_invoiced)}</p>
                </div>

                <UilDollarSignAlt />
              </Components.CardBox>
            </section>

            <section className="graphical__statistics">
              <div className="graphical__statistics-graphic">
                <h2>Faturamento acumulado</h2>

                <Components.CardBox customStyles={{ height: '18rem' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data?.accumulated_invoicing} margin={{ bottom: 0, right: 10, left: 20, top: 10 }}>
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Line type="temperature" dataKey="total_invoiced" stroke="var(--color-blue)" />
                    </LineChart>
                  </ResponsiveContainer>
                </Components.CardBox>
              </div>

              <div>
                <div>
                  <h2>Receita liquida do mês</h2>

                  <Components.CardBox className="recipe__cards">
                    <div className="recipe__cards-card">
                      <span>Total</span>
                      <p>{utils.formatPrice(data?.sales_summary?.sold)}</p>
                    </div>
                  </Components.CardBox>
                </div>

                <div>
                  <h2>Pedidos finalizados</h2>

                  <Components.CardBox className="recipe__cards">
                    <div className="recipe__cards-card">
                      <span>Total</span>
                      <p>{data?.sales_summary?.orders_done}</p>
                    </div>
                  </Components.CardBox>
                </div>
              </div>
            </section>

            <section className="box__footer">
              <div className="box__footer-sections">
                <header>
                  <h2>Valor médio por varejo</h2>
                  <button className="link">Total</button>
                </header>

                <div className="box__footer-cards">
                  <Components.CardBox className="recipe__cards">
                    <div className="recipe__cards-card">
                      <span>Hoje</span>
                      <p>{utils.formatPrice(data?.retailer_avg_invoices?.today_avg)}</p>
                    </div>

                    <UilDollarSignAlt />
                  </Components.CardBox>

                  <Components.CardBox className="recipe__cards">
                    <div className="recipe__cards-card">
                      <span>Mensal</span>
                      <p>{utils.formatPrice(data?.retailer_avg_invoices?.month_avg)}</p>
                    </div>

                    <UilDollarSignAlt />
                  </Components.CardBox>
                </div>
              </div>

              <div className="box__footer-sections">
                <header>
                  <h2>Formas de pagamento</h2>
                  <button className="link">Esse mês</button>
                </header>

                <div className="box__footer-cards">
                  {data?.rates_by_methods?.map(item => (
                    <Components.CardBox key={item.method} className="recipe__cards">
                      <div className="recipe__cards-card">
                        <span>{PAYMENT_METHOD[item.method]}</span>
                        <p>{item.rate}%</p>
                      </div>
                    </Components.CardBox>
                  ))}
                </div>
              </div>
            </section>
          </main>
        </Container>
      )}
    </>
  )
}

export { Financial }
