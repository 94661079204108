import { IIndustry } from '../../types'
import ApiClient from '../ApiClient'
import { IIndustryPayload, IIndustryResponse } from './interfaces'

const route = '/api/industries/'

const GetIndustry = (industry_id: string): Promise<IIndustryResponse> => ApiClient.api.get(route + industry_id)

const PatchIndustry = ({ industry_id, payload }: IIndustryPayload): Promise<IIndustry> =>
  ApiClient.api.patch(route + industry_id, payload)

export { GetIndustry, PatchIndustry }
