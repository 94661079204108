import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 1.6rem;
  width: 100%;

  ${({ customStyles }) => customStyles}

  span {
    margin: 0 1.6rem;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--color-primaryTwo);
    border-radius: 0.8rem;
    cursor: pointer;
    height: 3.2rem;
    width: 3.2rem;
    border: none;
  }
`
