import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__left {
      display: flex;
      align-items: center;
    }
  }

  main {
    .box__cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(12.8rem, 1fr));
      grid-gap: 1.6rem;

      .card {
        display: flex;
        justify-content: space-between;

        .card__values {
          span {
            color: var(--color-grey);
            font-size: 1.2rem;
            font-weight: 500;
          }

          p {
            margin: 3.2rem 0 0 0;
            font-weight: bold;
            font-size: 2.4rem;
          }
        }

        svg {
          color: var(--color-primaryTwo);
          height: 4rem;
          width: 4rem;
        }
      }
    }

    .box__search {
      display: grid;
      grid-template-columns: 1fr 25.6rem;
      grid-gap: 1.6rem;

      margin: 3.2rem 0;
    }
  }
`
