import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;

    h1 {
      margin: 0 0 0 1.6rem;
      font-weight: normal;
    }
  }

  form {
    max-width: 51.2rem;
    padding: 6.4rem 0;
    margin: 0 auto;

    p {
      display: flex;
      align-items: center;

      color: var(--color-grey);
      font-size: 1.2rem;

      svg {
        margin: 0 0.8rem 0 0;
      }
    }
  }
`
