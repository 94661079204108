import React, { useEffect } from 'react'
import { useQuery } from 'react-query'

import * as Components from '../../../components'
import { useAuth } from '../../../hooks/useAuth'
import { GetContacts } from '../../../services/Contacts'
import { toastify } from '../../../utils'
import { Container } from './styles'

const SettingsContacts: React.FC = () => {
  const { data } = useQuery('settings-contacts', fetchData)
  const { user } = useAuth()

  async function fetchData() {
    if (!user) return

    try {
      return await GetContacts(user.id)
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregar os contatos', 'error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Container>
      <header>
        <Components.ButtonBack />
        <h1>Contatos</h1>
      </header>

      <form>
        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.comercial}
          label="COMERCIAL"
          disabled
        />

        <Components.InputMask
          customStyles={{ marginBottom: '1.6rem' }}
          placeholder="Insira um número de telefone"
          value={data?.comercial_phone}
          mask="(99) 99999-9999"
          label="TELEFONE"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.comercial_email}
          label="E-MAIL"
          disabled
        />

        <div className="divider" />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.financial}
          label="FINANCEIRO"
          disabled
        />

        <Components.InputMask
          customStyles={{ marginBottom: '1.6rem' }}
          placeholder="Insira um número de telefone"
          value={data?.financial_phone}
          mask="(99) 9999-9999"
          label="TELEFONE"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.financial_email}
          label="E-MAIL"
          disabled
        />

        <div className="divider" />

        <Components.Input customStyles={{ marginBottom: '1.6rem' }} label="OUTROS" value={data?.others} disabled />

        <Components.InputMask
          customStyles={{ marginBottom: '1.6rem' }}
          placeholder="Insira um número de telefone"
          value={data?.others_phone}
          mask="(99) 9999-9999"
          label="TELEFONE"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.others_email}
          label="E-MAIL"
          disabled
        />
      </form>
    </Container>
  )
}

export { SettingsContacts }
