import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;

  font-size: 1.4rem;
  font-weight: 300;

  ${({ customStyles }) => customStyles}

  textarea {
    box-shadow: inset 0 0.5rem 1rem #00000020;
    border: 0.1rem solid #e6ebef;
    color: var(--color-grey);
    padding: 0.8rem 1.6rem;
    margin: 0.8rem 0 0 0;
    border-radius: 1rem;
    min-height: 12.4rem;
    background: white;
    outline: none;
  }

  span {
    color: var(--color-pink);
    margin-top: 0.4rem;
    font-size: 1.2rem;
    height: 1.2rem;
  }
`
