import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button, ButtonBack, Input, Select, Switch } from '../../../components'
import { BankCreate } from '../../../services/Banks'
import { logErros, toastify } from '../../../utils'
import { Container } from './styles'
import { schemeAccount, typeSuggestions } from './utils'

const FinancialCreate: React.FC = () => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(schemeAccount) })
  const { errors } = formState

  const [is_main, setIsMain] = useState(false)

  async function handleBankAccountCreation(payload: any): Promise<void> {
    Object.assign(payload, { is_main })

    try {
      await BankCreate(payload)
      toastify('Conta bancária criada com sucesso', 'success')
    } catch (error: any) {
      const messageError = logErros(error)

      if (!messageError) toastify('Ocorreu um erro na criação da conta bancária', 'error')
      else toastify(messageError, 'error')
    }
  }

  return (
    <Container>
      <header>
        <ButtonBack />
        <h1>Adicionar nova conta</h1>
      </header>

      <form onSubmit={handleSubmit(handleBankAccountCreation)}>
        <Input
          placeholder="Informe o CNPJ ou CPF"
          error={errors.document_number}
          label="Número do documento"
          name="document_number"
          register={register}
        />

        <Input label="Código bancário" name="bank_code" error={errors.bank_code} register={register} />

        <div style={{ display: 'grid', gridTemplateColumns: '2fr 12.8rem', gap: '1.6rem' }}>
          <Input label="Número da conta" name="account_number" error={errors.account_number} register={register} />
          <Input label="Dígito da conta" name="account_digit" error={errors.account_digit} register={register} />
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: '2fr 12.8rem', gap: '1.6rem' }}>
          <Input label="Agencia" name="agency" error={errors.agency} register={register} />
          <Input label="Dígito da agencia" name="agency_digit" error={errors.agency_digit} register={register} />
        </div>

        <Select
          suggestions={typeSuggestions}
          placeholder="Status"
          register={register}
          error={errors.type}
          name="type"
        />

        <Switch active={is_main} onClick={() => setIsMain(!is_main)}>
          Conta principal
        </Switch>

        <Button customStyles={{ margin: '3.2rem auto' }} type="submit">
          Salvar alterações
        </Button>
      </form>
    </Container>
  )
}

export { FinancialCreate }
