import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--color-primaryOne);
    margin: 0 0 3.2rem 0;
    font-weight: normal;
    font-size: 12.8rem;
  }

  button {
    text-decoration: underline;
    color: var(--color-blue);
    font-size: 1.2rem;

    background: transparent;
    margin-top: 1.6rem;
    cursor: pointer;
    border: none;
  }
`
