import React from 'react'

import { Container } from './styles'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  customStyles?: React.CSSProperties
  label?: string
}

const CardBox: React.FC<IProps> = ({ customStyles, children, label, ...rest }) => (
  <Container customStyles={customStyles} {...rest} label={!!label}>
    {label && <header className="header">{label}</header>}
    {children}
  </Container>
)

export { CardBox }
