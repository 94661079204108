import React from 'react'

import { Container } from './styles'

interface IProps extends React.ButtonHTMLAttributes<any> {
  customStyles?: React.CSSProperties
  neutralTemplate?: boolean
  clearTemplate?: boolean
}

const Button: React.FC<IProps> = ({ neutralTemplate, clearTemplate, customStyles, children, ...rest }) => (
  <Container customStyles={customStyles} neutralTemplate={neutralTemplate} clearTemplate={clearTemplate} {...rest}>
    {children}
  </Container>
)

export { Button }
