import { AxiosError } from 'axios'

export function logErros(error: AxiosError): string | undefined {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    const status = error?.response?.status

    switch (status) {
      case 400:
        console.group('Validation failed')

        console.log(error?.response?.data?.validation?.body?.message || error?.response?.data?.message)
        error?.response?.data?.validation?.body?.keys?.map((key: string) => console.log(key))

        console.groupEnd()

        return error?.response?.data?.validation?.body?.message || error?.response?.data?.message

      default:
        console.group('Response ERROR')

        console.log(`error.message >>> ${error?.response?.data?.message} <<<`)
        console.log(`error.status >>> ${status} <<<`)

        console.groupEnd()
        break
    }
  }
}
