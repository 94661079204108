import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;

  font-size: 1.2rem;

  ${({ customStyles }) => customStyles}

  .box {
    box-shadow: inset 0 0.25rem 0.8rem #00000010;
    border: 0.1rem solid #e6ebef;
    border-radius: 0.4rem;
    margin-right: 0.8rem;
    position: relative;
    background: white;
    height: 1.8rem;
    width: 1.8rem;

    cursor: pointer;

    input {
      visibility: hidden;
    }

    .check {
      color: var(--color-blue);
      height: 2rem;
      width: 2rem;

      position: absolute;
      left: -0.1rem;
      top: -0.1rem;
    }
  }
`
