import React from 'react'

import { UilCheck } from '@iconscout/react-unicons'

import { Container } from './style'

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  setState?: React.Dispatch<React.SetStateAction<boolean>>
  customStyles?: React.CSSProperties
  checked: boolean
}

const CheckBox: React.FC<IProps> = ({ customStyles, checked, children, setState, ...rest }) => {
  function handleChangeState() {
    if (setState) {
      setState(!checked)
    }
  }

  return (
    <Container customStyles={customStyles}>
      <div onClick={handleChangeState} className="box">
        <input type="checkbox" {...rest} />
        {checked && <UilCheck className="check" />}
      </div>

      {children}
    </Container>
  )
}

export { CheckBox }
