import ApiClient from '../ApiClient'
import { IBankPayload, IBanksList, IBanksParams, IBankUpdate } from './interfaces'

const route = '/api/industries/bank_accounts/'

const BankUpdate = ({ payload, id }: IBankUpdate): Promise<void> => ApiClient.api.patch(route + id, payload)
const BankChangeMainAccount = (id: string): Promise<void> => ApiClient.api.put(route + 'change_main/' + id)
const BanksList = (params?: IBanksParams): Promise<IBanksList> => ApiClient.api.get(route, { params })
const BankCreate = (payload: IBankPayload): Promise<void> => ApiClient.api.post(route, payload)
const BankDelete = (id: string): Promise<void> => ApiClient.api.delete(route + id)

export { BankChangeMainAccount, BankDelete, BankUpdate, BankCreate, BanksList }
