import React, { useRef } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import * as utils from '../../utils'
import { Container } from './styles'

import * as Components from '..'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  setPreviewImage: React.Dispatch<React.SetStateAction<any>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  previewImage: string | undefined
  isOpen: boolean
  title: string
}

const ModalCropImage: React.FC<IProps> = ({ setPreviewImage, setIsOpen, previewImage, isOpen, title }) => {
  const cropperRef = useRef<HTMLImageElement>(null)

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const maximumSize = 4 * 1024 * 1024

    const selectedImages = Array.from(event.target.files)

    const [selectedImagesPreview] = selectedImages?.map(image => {
      if (image.type !== 'image/jpg' && image.type !== 'image/jpeg') {
        utils.toastify('A imagem deve ser no formato .jpg ou .jpeg', 'error')
        return ''
      }

      if (image.size >= maximumSize) {
        utils.toastify('Insira uma imagem menor que 4MB', 'error')
        return ''
      }

      return URL.createObjectURL(image)
    })

    setPreviewImage(selectedImagesPreview)
  }

  const handleGetCropData = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper

    if (typeof cropper !== 'undefined') {
      const fileBase64 = cropper.getCroppedCanvas().toDataURL()
      setPreviewImage(fileBase64)
      setIsOpen(false)
    }
  }

  return (
    <Components.Modal title={title} setIsOpen={setIsOpen} isOpen={isOpen}>
      <Container>
        <div className="box__image">
          <Cropper style={{ height: '40rem', width: '40rem' }} src={previewImage} ref={cropperRef} aspectRatio={1} />

          <input onChange={handleChangeImage} accept="image/jpg, image/jpeg" id="image[]" type="file" />

          <div className="box__options">
            <label htmlFor="image[]" className="link">
              inserir nova imagem
            </label>

            <button type="button" className="link" onClick={handleGetCropData}>
              Salvar alteração
            </button>
          </div>
        </div>
      </Container>
    </Components.Modal>
  )
}

export { ModalCropImage }
