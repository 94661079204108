import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilStar } from '@iconscout/react-unicons'

import iconFood from '../../../assets/images/iconFood.svg'
import { ButtonBack, CardBox } from '../../../components'
import { GetReview } from '../../../services/Reviews'
import { IGetReviewResponse } from '../../../services/Reviews/interfaces'
import { formatDate, formatMask, formatPrice, toastify } from '../../../utils'
import { Container } from './styles'

const ReviewsDetails: React.FC = () => {
  const { review_id } = useParams<'review_id'>()

  const [review, setReview] = useState<IGetReviewResponse | undefined>()

  async function fetchData() {
    if (!review_id) return

    try {
      const response = await GetReview(review_id)
      setReview(response)
    } catch (error) {
      toastify('Erro ao carregar informações da avaliação', 'error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Container>
      <header>
        <ButtonBack customStyles={{ marginRight: '1.6rem' }} />
        <h1>Mais detalhes</h1>
      </header>

      <main>
        <div className="title__list">
          <span>Data</span>
          <span>Núm. do pedido</span>
          <span>Avaliação pedido</span>
          <span>Nome varejista</span>
          <span>Avaliação indústria</span>
          <span>Avaliação entrega</span>
          <span>Comentários</span>
        </div>

        <CardBox>
          <div className="box__details">
            <span>{formatDate(review?.created_at)}</span>
            <span>{review?.order?.order_number}</span>

            <span>
              <UilStar /> {review?.score}
            </span>

            <span>{review?.order?.retailer?.company_name}</span>

            <span>
              <UilStar /> {review?.industry_review?.score}
            </span>

            <span>
              <UilStar /> {review?.shipment_score}
            </span>

            <span>{review?.industry_review?.description}</span>
          </div>
        </CardBox>

        <CardBox label="Produtos" customStyles={{ margin: '3.2rem 0' }}>
          <ul>
            {review?.order?.itens?.map(item => (
              <li key={item.id}>
                <img src={item.image || iconFood} alt="" />

                <div>
                  <strong className="margin-bottom">{item.product}</strong>

                  <p>
                    <strong>Qntdd.: </strong>
                    {item.amount} {item.measure_unit?.toLocaleLowerCase()}(s)
                  </p>
                </div>

                <div>
                  <p className="margin-bottom">
                    <strong>Lote: </strong>
                    {item.fifo_pack_number || 'não se aplica'}
                  </p>

                  <p>
                    <strong>Validade: </strong>
                    {formatDate(item.fifo_expire_date)}
                  </p>
                </div>

                <div>
                  <strong className="margin-bottom">SKU do produto</strong>
                  {item.sku || 'não se aplica'}
                </div>

                <div>
                  <strong className="margin-bottom">Código de barras DUN14</strong>
                  {item.dum14_code || 'não se aplica'}
                </div>

                <div>
                  <strong className="margin-bottom">Código de barras EAN13</strong>
                  {item.ean13_code || 'não se aplica'}
                </div>

                <div className="fex-end">
                  <strong className="margin-bottom">Valor por {item.measure_unit?.toLocaleLowerCase()}</strong>
                  {formatPrice(item.unit_value)}
                </div>
              </li>
            ))}
          </ul>
        </CardBox>

        <CardBox label="Entrega" className="box__address">
          <div className="address">
            <strong>
              {`${review?.order?.shipment?.address}, ${review?.order?.shipment?.address_number}, ${review?.order?.shipment?.complement}`}
            </strong>

            <p>
              {review?.order?.shipment?.city}/{review?.order?.shipment?.state}
              <br />
              CEP: {formatMask(review?.order?.shipment?.zip_code, '#####-###')}
            </p>
          </div>

          <div className="address">
            <strong>Responsável pelo recebimento:</strong>
            <p>{review?.order?.shipment?.responsible_receiving}</p>
          </div>
        </CardBox>
      </main>
    </Container>
  )
}

export { ReviewsDetails }
