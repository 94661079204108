import React from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'

import { UilSearch } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  customStyles?: React.CSSProperties
  iconSearch?: boolean
  label?: string

  register?: UseFormRegister<FieldValues>
  error?: FieldError
}

const Input: React.FC<IProps> = ({ customStyles, iconSearch, register, error, label, ...rest }) => {
  return (
    <Container customStyles={customStyles}>
      {label}

      <div className="box__input">
        {iconSearch && <UilSearch color="var(--color-secondaryOne)" />}

        {register && rest.name ? <input {...register(rest.name)} {...rest} /> : <input {...rest} />}
      </div>

      <span>{!!error && error.message}</span>
    </Container>
  )
}

export { Input }
