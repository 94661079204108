import ApiClient from '../ApiClient'
import {
  IGeneralPendingOrders,
  IPendingOrdersParams,
  IGeneralSalesSummary,
  IGeneralBestSellers,
  IBestSellersParams,
  IGeneralRanking,
  IRankingParams
} from './interfaces'

const route = '/api/industries/general/'

const GeneralPendingOrders = (params?: IPendingOrdersParams): Promise<IGeneralPendingOrders> =>
  ApiClient.api.get(route + 'pending_orders', { params })

const GeneralBestSellers = (params?: IBestSellersParams): Promise<IGeneralBestSellers> =>
  ApiClient.api.get(route + 'best_sellers', { params })

const GeneralRanking = (params?: IRankingParams): Promise<IGeneralRanking> =>
  ApiClient.api.get(route + 'ranking', { params })

const GeneralSalesSummary = (): Promise<IGeneralSalesSummary> => ApiClient.api.get(route + 'sales_summary')

export { GeneralPendingOrders, GeneralSalesSummary, GeneralBestSellers, GeneralRanking }
