import * as yup from 'yup'

export const schemeAccount = yup.object().shape({
  document_number: yup
    .string()
    .matches(/^[0-9]+$/, 'Informe somente números')
    .min(11, 'Você deve informar no mínimo 11 caracteres')
    .max(14, 'Você deve informar no máximo 14 caracteres'),

  account_number: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^[0-9]+$/, 'Informe somente números')
    .max(50, 'Você deve informar no máximo 50 caracteres'),

  account_digit: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^[0-9]+$/, 'Informe somente números')
    .max(1, 'Você deve informar no máximo 1 caracteres'),

  agency: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^[0-9]+$/, 'Informe somente números')
    .max(50, 'Você deve informar no máximo 50 caracteres'),

  agency_digit: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^[0-9]+$/, 'Informe somente números')
    .max(1, 'Você deve informar no máximo 1 caracteres'),

  bank_code: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^[0-9]+$/, 'Informe somente números')
    .max(50, 'Você deve informar no máximo 50 caracteres'),

  type: yup.string().required('Campo obrigatório')
})

export const typeSuggestions = [
  { value: 'checking_account', text: 'Conta corrente' },
  { value: 'savings_account', text: 'Conta poupança' }
]
