import { initializeApp, FirebaseOptions } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
} as FirebaseOptions

const firebaseClient = initializeApp(firebaseConfig)
const firebaseStorage = getStorage(firebaseClient)

async function firebaseAuth(token: string): Promise<void> {
  try {
    const auth = getAuth()
    await signInWithCustomToken(auth, token)
  } catch (error) {
    console.log('Erro de autenticação do firebase', error)
  }
}

export { firebaseStorage, firebaseAuth }
