import React from 'react'

import { motion } from 'framer-motion'

import { Container } from './styles'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  customStyles?: React.CSSProperties
  active: boolean
}

const Switch: React.FC<IProps> = ({ customStyles, children, active, ...rest }) => (
  <Container active={active} {...rest} customStyles={customStyles}>
    <div>
      <motion.div className="switch__span" layout transition={{ stiffness: 700, type: 'spring', damping: 30 }} />
    </div>

    {children && <p>{children}</p>}
  </Container>
)

export { Switch }
