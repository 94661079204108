import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Container } from './styles'

const PageNotFound: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <h1>404</h1>

      <p>Você não possui permissão ou a página não foi encontrada!!!</p>

      <button onClick={() => navigate(-1)}>Click aqui para voltar</button>
    </Container>
  )
}

export { PageNotFound }
