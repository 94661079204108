import styled, { keyframes, css } from 'styled-components'

const bubble = keyframes`
  0%,
  80%,

  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

interface IContainerProps {
  space?: string
  size?: string
}

export const Container = styled.div<IContainerProps>`
  font-size: ${props => props.size || '10px'};
  transform: translateZ(0);
  animation-delay: -0.16s;
  text-indent: -9999em;
  position: relative;
  margin: 0 auto;
  color: #262626;

  -webkit-transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  -ms-transform: translateZ(0);

  &,
  &:before,
  &:after {
    animation: ${bubble} 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    border-radius: 50%;
    height: 2.5em;
    width: 2.5em;

    -webkit-animation: ${bubble} 1.8s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 0;
  }

  &:before {
    animation-delay: -0.32s;
    left: -3.5em;

    -webkit-animation-delay: -0.32s;
  }

  &:after {
    left: 3.5em;
  }

  ${props =>
    props.space &&
    css`
      margin-bottom: ${props.space.length || '50px'};
    `}
`
