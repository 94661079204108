import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-size: 1.4rem;
  font-weight: 300;
  height: 8.5rem;

  ${({ customStyles }) => customStyles}

  .box__input {
    box-shadow: inset 0 0.5rem 1rem #00000020;
    border: 0.1rem solid #e6ebef;
    padding: 0;
    border-radius: 1rem;
    background: white;

    select {
      color: var(--color-grey);
      background: transparent;
      outline: none;
      border: none;
      width: 100%;
    }
  }

  span {
    color: var(--color-pink);
    margin-top: 0.4rem;
    font-size: 1.2rem;
  }
`
