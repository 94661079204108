import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    font-weight: normal;
    margin: 0;
  }

  .header__options {
    display: grid;
    grid-template-columns: 1fr repeat(2, 25.6rem);
    gap: 1.6rem;
  }

  .container__brands {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25.6rem, 1fr));
    grid-gap: 1.6rem;

    margin-top: 3.2rem;

    .box__brand {
      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 1.6rem;

      img {
        border: 0.1rem solid var(--color-primaryTwo);
        border-radius: 0.8rem;
        object-fit: cover;
        height: 24rem;
        width: 24rem;
      }

      h2 {
        margin: 1.6rem 0 0 0;
        font-weight: normal;
        text-align: center;
        font-size: 1.6rem;
      }

      .divider {
        margin: 1.6rem 0 1.6rem 0;
        background: #00000010;
        height: 0.1rem;
        width: 100%;
      }

      button {
        font-size: 1.2rem;
      }
    }
  }
`

export const BoxImageContainer = styled.div`
  display: grid;
  grid-template-columns: 25rem 40rem;
  grid-gap: 3.2rem;

  .box__image {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 25rem;
    width: 100%;

    img {
      border: 1px solid var(--color-primaryTwo);
      border-radius: 1.6rem;
      object-fit: cover;
      cursor: pointer;
      height: 25rem;
      width: 25rem;
    }

    button {
      margin-top: 1.6rem;
    }
  }
`

export const BoxModalDelete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 3.2rem;
  }

  .box__options {
    display: flex;
    justify-content: space-between;
    width: 34rem;
  }
`
