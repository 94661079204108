import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { ButtonBack, Button, Modal, Table, Loading } from '../../../components'
import { BankChangeMainAccount, BanksList } from '../../../services/Banks'
import { toastify } from '../../../utils'
import { ConfirmaModal, Container } from './styles'
import { exampleTitleMainAccount } from './utils'

const FinancialAlteredAccount: React.FC = () => {
  const { refetch, isLoading, isFetched, data } = useQuery('financial/altered-accounts', fetchData)

  const [params, setParams] = useState({ pageOffset: 6, page: 1 })
  const [accountSelect, setAccountSelect] = useState<any>()
  const [openModal, setOpenModal] = useState(false)

  async function fetchData() {
    try {
      const { results: bankMain } = await BanksList({ pageOffset: 1 })
      const { results: banks, total_pages } = await BanksList(params)

      return { total_pages, bankMain, banks }
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregas os dados dessa tela', 'error')
    }
  }

  async function handleChangeMainAccount() {
    try {
      if (accountSelect?.id) await BankChangeMainAccount(accountSelect.id)
      toastify('Atualização da conta principal realizada com sucesso', 'success')
      setOpenModal(false)
    } catch (error) {
      toastify('Ocorreu um erro ao tentar atualizar a conta principal', 'error')
    }
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params])

  return (
    <>
      <Modal title="Atenção!" isOpen={openModal} setIsOpen={setOpenModal}>
        <ConfirmaModal>
          <p>Deseja alterar sua conta principal?</p>

          <div className="box__options">
            <Button onClick={() => setOpenModal(false)} customStyles={{ marginRight: '3.2rem' }} clearTemplate>
              Não
            </Button>

            <Button onClick={handleChangeMainAccount}>Sim</Button>
          </div>
        </ConfirmaModal>
      </Modal>

      {isLoading && <Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <ButtonBack customStyles={{ marginRight: '1.6rem' }} />
            <h1>Alterar conta principal</h1>
          </header>

          <main>
            <section className="main__account">
              <header>
                <h2>Conta principal</h2>
              </header>

              <Table titles={exampleTitleMainAccount} data={data?.bankMain} disableHover />
            </section>

            <section className="others__accounts">
              <header>
                <h2>Outras contas cadastradas</h2>
              </header>

              <Table
                handlePreviousPage={handlePreviousPage}
                totalPages={data?.total_pages || 1}
                handleSetState={setAccountSelect}
                titles={exampleTitleMainAccount}
                handleNextPage={handleNextPage}
                currentPage={params.page}
                getState={accountSelect}
                data={data?.banks}
                hasSelector
                hasPaginate
              />
            </section>

            <Button
              customStyles={{ margin: '0 auto', marginTop: '6.4rem' }}
              onClick={() => setOpenModal(true)}
              disabled={!accountSelect?.id}
            >
              Salvar alterações
            </Button>
          </main>
        </Container>
      )}
    </>
  )
}

export { FinancialAlteredAccount }
