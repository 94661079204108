import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import iconFood from '../../assets/images/iconFood.svg'
import { Button, CardBox, Input, Loading, Paginate } from '../../components'
import { SegmentsIndex } from '../../services/Segments'
import { toastify } from '../../utils'
import { Container } from './styles'

const Segments: React.FC = () => {
  const { isLoading, isFetched, data, refetch } = useQuery('segments', fetchData)
  const [params, setParams] = useState({ pageOffset: 18, page: 1 })

  async function fetchData() {
    try {
      return await SegmentsIndex(params)
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregas os seguimentos', 'error')
    }
  }

  function handleChangeState(event: any) {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params.page])

  return (
    <Container>
      <h1>Segmentos</h1>

      <div className="search__options">
        <Input
          customStyles={{ maxWidth: '51.2rem', width: '100%' }}
          onChange={handleChangeState}
          name="description"
          iconSearch
        />

        <Button onClick={() => refetch()} customStyles={{ marginTop: '2.4rem' }}>
          Pesquisar
        </Button>
      </div>

      {isLoading && <Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <>
          <div className="container__segments">
            {data?.results?.map(segment => (
              <CardBox key={segment.id} className="box__segment">
                <img src={segment?.image || iconFood} alt="" />

                <div className="box__segment-content">
                  <h2>{segment?.description}</h2>
                  <span>{segment?.total_products} produtos</span>
                </div>
              </CardBox>
            ))}
          </div>

          <Paginate
            customStyles={{ position: 'fixed', bottom: '1.6rem' }}
            handlePreviousPage={handlePreviousPage}
            totalPages={data?.total_pages || 1}
            handleNextPage={handleNextPage}
            currentPage={params.page}
          />
        </>
      )}
    </Container>
  )
}

export { Segments }
