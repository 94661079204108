import { ITitle } from '../../../components/Table'
import { formatDate, formatPrice } from '../../../utils'

export const tableTitle: ITitle[] = [
  { title: 'Período', attribute: 'date', formatter: formatDate },
  { title: 'Código', attribute: 'bank_code' },
  { title: 'Banco', attribute: 'bank_name' },
  { title: 'Conta', attribute: 'account_formatted' },
  { title: 'Agência', attribute: 'agency_formatted' },
  { title: 'CPF/CNPJ', attribute: 'document_number' },
  { title: 'Nro. Pedido', attribute: 'order_number' },
  { title: 'Valor', attribute: 'value', formatter: formatPrice },
  { title: 'Recibo', attribute: 'receipt_formatted' },
  { title: 'Status', attribute: 'status_formatted' }
]

export const financialSuggestions = [
  { value: 'pending', text: 'Em análise' },
  { value: 'transferred', text: 'Transferido' },
  { value: 'expected_deposit', text: 'A receber' }
]
