import React, { useState } from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'

import { UilEyeSlash, UilEye } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.InputHTMLAttributes<any> {
  customStyles?: React.CSSProperties
  label?: string
  name: string

  register: UseFormRegister<FieldValues>
  error: FieldError
}

const InputPassword: React.FC<IProps> = ({ customStyles, register, error, label, ...rest }) => {
  const [passwordShow, setPasswordShow] = useState(false)

  const togglePasswordVisibility = () => setPasswordShow(!passwordShow)

  return (
    <Container customStyles={customStyles}>
      {label}

      <div className="box__input">
        <input {...register(rest.name)} {...rest} type={passwordShow ? 'text' : 'password'} />

        <button type="button" onClick={togglePasswordVisibility} className="eyePassword">
          {passwordShow ? <UilEye title="Mostrar senha" /> : <UilEyeSlash title="Ocultar senha" />}
        </button>
      </div>

      <span>{!!error && error.message}</span>
    </Container>
  )
}

export { InputPassword }
