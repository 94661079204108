import * as yup from 'yup'

export const schemeCreateBrand = yup.object().shape({
  description: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório')
})

export const schemeEditBrand = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  description: yup.string().default('')
})
