import * as yup from 'yup'

export const scheme = yup.object().shape({
  segments: yup.string().uuid('Você precisa selecionar uma categoria').required('Campo obrigatório'),
  subsegments: yup.array().of(yup.string().uuid('Cada subcategoria deve ser uma UUID')).required('Campo obrigatório'),
  brand_id: yup.string().uuid('Você precisa selecionar uma marca').required('Campo obrigatório')
})

export const schemeFifo = yup.object().shape({
  segments: yup.string().uuid('Você precisa selecionar um segmento').required('Campo obrigatório'),
  brand_id: yup.string().uuid('Você precisa selecionar uma marca').required('Campo obrigatório'),

  product_fifo: yup.object({
    pack_number: yup.string().required('Campo obrigatório'),
    expire_date: yup.string().required('Campo obrigatório'),
    value: yup.string().required('Campo obrigatório')
  })
})

export const filterUpdateProduct = [
  'integration_id',
  'measureUnit',
  'metrics',
  'images',
  'brand',
  'value',
  'id',
  'amounts',
  'conversion_rate',
  'invoiceMeasureUnit',
  'product_amounts',
  'pack_unit_value',
  'review_score'
]
