import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

const SkeletonBase = styled.div<IContainerProps>`
  border-radius: 0.4rem;
  background: #00000010;
  margin: 1rem 0;
`

export const SkeletonThumbnail = styled(SkeletonBase)`
  height: 8rem;
  width: 8rem;
`
export const SkeletonAvatar = styled(SkeletonBase)`
  border-radius: 50%;
  height: 8rem;
  width: 8rem;
`

export const SkeletonTitle = styled(SkeletonBase)`
  margin-bottom: 1.6rem;
  height: 2rem;
  width: 50%;
`

export const SkeletonText = styled(SkeletonBase)`
  height: 1.6rem;
  width: 100%;
`
