import React from 'react'

import { SkeletonAvatar, SkeletonText, SkeletonThumbnail, SkeletonTitle } from './styles'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  shape?: 'thumbnail' | 'avatar' | 'title' | 'text'
  customStyles?: React.CSSProperties
}

const Skeleton: React.FC<IProps> = ({ customStyles, shape }) => {
  switch (shape) {
    case 'thumbnail':
      return <SkeletonThumbnail customStyles={customStyles} />
    case 'avatar':
      return <SkeletonAvatar customStyles={customStyles} />
    case 'title':
      return <SkeletonTitle customStyles={customStyles} />
    case 'text':
      return <SkeletonText customStyles={customStyles} />
    default:
      return <SkeletonText customStyles={customStyles} />
  }
}

export { Skeleton }
