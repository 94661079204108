import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import iconFood from '../../../assets/images/iconFood.svg'
import { ButtonBack, CardBox, Input, Loading, Skeleton } from '../../../components'
import { GetOrders } from '../../../services/Orders'
import { formatDate, formatMask, formatPrice, toastify } from '../../../utils'
import { Container } from './styles'

import { ORDER_STATUS, PAYMENT_METHOD, PAYMENT_STATUS } from '../../../constants'

const RetailersOrderDetails: React.FC = () => {
  const { order_id } = useParams<'order_id'>()
  const { isLoading, isFetched, data } = useQuery(`retailers/order/${order_id}`, fetchData)

  async function fetchData() {
    try {
      if (order_id) return await GetOrders(order_id)
    } catch (error) {
      toastify('Erro ao carregar detalhes do pedido', 'error')
    }
  }

  return (
    <>
      {isLoading && <Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <div className="header__left">
              <ButtonBack customStyles={{ marginRight: '1.6rem' }} />
              <h1>Pedido {data?.order_number}</h1>
              <span>{data?.retailer?.company_name}</span>
            </div>
          </header>

          <main>
            <CardBox
              customStyles={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1.6rem' }}
              label="Pedido"
            >
              <Input label="STATUS" defaultValue={ORDER_STATUS[data?.status || '']} disabled />
              <Input label="NÚMERO DO PEDIDO" defaultValue={data?.order_number} disabled />
            </CardBox>

            <CardBox
              label="Dados do cliente"
              customStyles={{
                gridTemplateColumns: 'repeat(5, 1fr)',
                marginTop: '3.2rem',
                gridGap: '1.6rem',
                display: 'grid'
              }}
            >
              <div>
                <strong>Nome do varejista:</strong>
                <p>{data?.retailer?.company_name}</p>
              </div>

              <div>
                <strong>Telefone:</strong>
                <p>{formatMask(data?.retailer?.phone_number, '(##) # ####-####')}</p>
              </div>

              <div>
                <strong>E-mail:</strong>
                <p>{data?.retailer?.email}</p>
              </div>

              <div>
                <strong>CNPJ:</strong>
                <p>{formatMask(data?.retailer?.cnpj, '##.###.###/####-##')}</p>
              </div>

              <div>
                <strong>Núm. de pedidos:</strong>
                <p>{data?.retailer?.total_orders} pedidos</p>
              </div>
            </CardBox>

            <CardBox
              label="Registro de envio"
              customStyles={{
                gridTemplateColumns: 'repeat(3, 1fr)',
                marginTop: '3.2rem',
                gridGap: '1.6rem',
                display: 'grid'
              }}
            >
              <Input label="CÓDIGO DE RASTREIO" defaultValue={data?.shipment?.track_code} disabled />

              <Input
                label="DATA DA POSTAGEM"
                defaultValue={formatDate(data?.shipment?.dispatched_at)}
                disabled
                type="date"
              />

              <Input
                label="DATA ESTIMADA PARA ENTREGA"
                defaultValue={formatDate(data?.shipment?.estimated_date)}
                disabled
                type="date"
              />
            </CardBox>

            <CardBox
              label="Pagamento"
              customStyles={{
                gridTemplateColumns: 'repeat(6, 1fr)',
                marginTop: '3.2rem',
                gridGap: '1.6rem',
                display: 'grid'
              }}
            >
              <div>
                <strong>Forma de pagamento:</strong>
                <p>{PAYMENT_METHOD[data?.orderPayment?.method || '']}</p>
              </div>

              <div>
                <strong>Status:</strong>
                <p>{PAYMENT_STATUS[data?.orderPayment?.status || '']}</p>
              </div>
            </CardBox>

            <CardBox
              label="Registro de envio"
              customStyles={{
                gridTemplateColumns: 'repeat(4, 1fr)',
                marginTop: '3.2rem',
                gridGap: '1.6rem',
                display: 'grid'
              }}
            >
              <Input customStyles={{ gridColumn: '1 / 3' }} label="CHAVE DA NOTA FISCAL" defaultValue="XXX" disabled />
              <Input label="NÚMERO DA NOTA FISCAL" defaultValue="XXX" disabled />
              <Input label="SÉRIE DA NOTA FISCAL" defaultValue="XXX" disabled />
              <Input label="DATA DO FATURAMENTO" defaultValue="XXX" disabled />
              <Input customStyles={{ gridColumn: '2 / 5' }} label="LINK DA NOTA FISCAL" defaultValue="XXX" disabled />
            </CardBox>

            <CardBox
              label="Endereço de entrega"
              customStyles={{
                gridTemplateColumns: 'repeat(4, 1fr)',
                marginTop: '3.2rem',
                gridGap: '1.6rem',
                display: 'grid'
              }}
            >
              <div>
                {data ? (
                  <>
                    <strong>
                      {data?.shipment?.address}, {data?.shipment?.address_number},{data?.shipment?.complement}
                    </strong>

                    <p>
                      {formatMask(data?.shipment?.zip_code, '#####-###')} - {data?.shipment?.city} -{' '}
                      {data?.shipment?.state}
                    </p>
                  </>
                ) : (
                  <>
                    <Skeleton shape="title" />
                    <Skeleton />
                  </>
                )}
              </div>

              <div>
                <strong>Responsável pelo recebimento:</strong>
                <p>{data?.shipment?.responsible_receiving || <Skeleton />}</p>
              </div>
            </CardBox>

            <CardBox label="Produtos" customStyles={{ margin: '3.2rem 0' }}>
              <ul>
                {data?.itens?.map(item => (
                  <li key={item.id}>
                    <img src={item?.image || iconFood} alt="" />

                    <div>
                      <strong className="margin-bottom">
                        {item?.product} - {item?.amount + item?.measure_unit_symbol}
                      </strong>

                      <p>
                        <strong>Qntdd.: </strong>
                        {item?.amount} {item?.measure_unit?.toLocaleLowerCase()}(s)
                      </p>
                    </div>

                    <div>
                      <p className="margin-bottom">
                        <strong>Lote: </strong>
                        {item?.fifo_pack_number || 'não se aplica'}
                      </p>

                      <p>
                        <strong>Validade: </strong>
                        {formatDate(item?.fifo_expire_date)}
                      </p>
                    </div>

                    <div>
                      <strong className="margin-bottom">SKU do produto</strong>
                      {item?.sku || 'não se aplica'}
                    </div>

                    <div className="fex-end">
                      <strong className="margin-bottom">Valor por {item?.measure_unit?.toLocaleLowerCase()}</strong>
                      {formatPrice(item?.unit_net_value)}
                    </div>
                  </li>
                ))}
              </ul>
            </CardBox>
          </main>
        </Container>
      )}
    </>
  )
}

export { RetailersOrderDetails }
