import React from 'react'

import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  handlePreviousPage: () => void
  handleNextPage: () => void
  customStyles?: React.CSSProperties
  currentPage: number
  totalPages: number
}

const Paginate: React.FC<IProps> = ({ handlePreviousPage, handleNextPage, customStyles, currentPage, totalPages }) => (
  <Container customStyles={customStyles}>
    <button onClick={handlePreviousPage} disabled={currentPage === 1}>
      <UilAngleLeft color="black" />
    </button>

    <span>
      {currentPage} de {totalPages}
    </span>

    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
      <UilAngleRight color="black" />
    </button>
  </Container>
)

export { Paginate }
