import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Cropper } from 'react-cropper'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { UilTrashAlt, UilPlus } from '@iconscout/react-unicons'

import * as Components from '../../../components'

import { BrandsGetSimpleList } from '../../../services/Brands'
import { GetMeasureUnitsSimpleList } from '../../../services/MeasureUnits'
import { ProductsShow, ProductsUpdate } from '../../../services/Products'
import { IProductShowResponse } from '../../../services/Products/interfaces'
import { SegmentsSimpleList } from '../../../services/Segments'
import * as utils from '../../../utils'
import { Container, ModalContainer } from './styles'
import { filterUpdateProduct, scheme, schemeFifo } from './utilsProductHandle'
import { ISuggestions } from '../../../components/Autocomplete'

interface ISelectList {
  value: string
  unit?: string
  text: string
}

interface IStatePrices {
  product_price?: number | string
  fifo_price?: number | string
}

interface ICustomSubSegmentList {
  id: string
  description: string
  parent_id: string
}

const ProductsHandle: React.FC = () => {
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined)
  const [imagesPreview, setImagesPreview] = useState<string[]>()

  const [segmentsList, setSegmentsList] = useState<ISelectList[]>([])
  const [subSegmentsList, setSubsegmentsList] = useState<ICustomSubSegmentList[]>([])
  const [filteredSubSegmentsList, setFilteredSubSegmentList] = useState<ICustomSubSegmentList[]>([])
  const [selectedSegment, setSelectedSegment] = useState<string | undefined>(undefined)
  const [selectedSubSegment, setSelectedSubSegment] = useState<Array<string> | undefined>(undefined)
  const [measureList, setMeasureList] = useState<ISelectList[]>([])
  const [brandsList, setBrandsList] = useState<ISelectList[]>([])
  const [product, setProduct] = useState<IProductShowResponse>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [prices, setPrices] = useState<IStatePrices>({})
  const [isUpdating, setIsUpdating] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [isFifo, setIsFifo] = useState(false)

  const cropperRef = useRef<HTMLImageElement>(null)
  const location: any = useLocation()

  // eslint-disable-next-line
  const { formState, control, handleSubmit, setValue, register } = useForm({ resolver: yupResolver(isFifo ? schemeFifo : scheme) })
  const { errors } = formState

  async function fetchData() {
    try {
      const responseSegmentsList = await SegmentsSimpleList({ only_mine: false })
      const response = await ProductsShow(location.state.product.id)
      const responseMeasureList = await GetMeasureUnitsSimpleList()
      const responseBrandsList = await BrandsGetSimpleList()


      const segmentsListFormatted: ISuggestions[] = []
      const subSegmentListFormated: ICustomSubSegmentList[] = []
      responseSegmentsList.map(item => {
        segmentsListFormatted.push({
          text: item.segment,
          value: item.id
        })

        item.subsegments.map(subsegment => subSegmentListFormated.push({
          id: subsegment.id,
          description: subsegment.description,
          parent_id: item.id
        }))
      })

      const brandsListFormatted = responseBrandsList.map(({ name: text, id: value }) => ({ text, value }))
      const measureListFormatted = responseMeasureList.map(measure => ({
        ...measure,
        text: measure.description,
        unit: measure.unit_symbol,
        value: measure.id
      }))

      setSegmentsList(segmentsListFormatted)
      setSubsegmentsList(subSegmentListFormated)
      setMeasureList(measureListFormatted)
      setBrandsList(brandsListFormatted)

      setPrices({ product_price: response?.value, fifo_price: response?.fifo?.value })
      setEditing(!!response)
      handleSetProduct(response)

      setImagesPreview(response.images)
      setProduct(response)

    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar carregar detalhes do produto', 'error')
    }
  }

  async function handleUpdateProduct(payload: any) {
    setIsUpdating(true)
    let updatePayload = {}
    console.log('payload', payload)

    if (!imagesPreview || (imagesPreview && imagesPreview.length === 0)) {
      return utils.toastify('Você precisa anexar imagens ao produto', 'error')
    }

    const { product_fifo, id } = payload
    const { fifo_price } = prices
    const compressedFiles = []

    try {
      for (const image of imagesPreview) {
        const isUrl = image.includes('https://')

        if (!isUrl) {
          const byteCharacters = utils.convertBase64ToFile(image)
          const fileCompressed = await utils.handleImageCompress(byteCharacters)

          compressedFiles.push(fileCompressed)
          continue
        }

        compressedFiles.push(image)
      }

      const product_images = await utils.uploadImages(compressedFiles)

      // Object.assign(payload, {
      //   segments: [payload.subsegments],
      //   replace_segments: true,
      //   replace_images: true,
      //   product_images
      // })

      updatePayload = {
        ...updatePayload,
        brand_id:payload.brand_id,
        segments: payload.subsegments,
        replace_segments: true,
        replace_images: true,
        product_images
      }

      delete payload.subsegments

      if (!isFifo) delete payload.product_fifo
      if (isFifo) {
        const value = typeof fifo_price === 'string' ? +fifo_price.replace(/\D/g, '') : fifo_price
        // Object.assign(payload, { product_fifo: { ...product_fifo, value } })
        updatePayload = {
          ...updatePayload,
          product_fifo: {
            ...product_fifo,
            value
          }
        }
      }

      utils.clearObject(payload, filterUpdateProduct)

      await ProductsUpdate({
        id,
        payload: updatePayload as any
      })

      utils.toastify('Produto editado com sucesso', 'success')
    } catch (error: any) {
      const messageError = utils.logErros(error)

      if (!messageError) utils.toastify('Ocorreu um erro ao tentar editar o produto', 'error')
      else utils.toastify(messageError, 'error')
    }

    setIsUpdating(false)
  }

  function handleSetProduct(product: IProductShowResponse): void {
    for (const prop in product) {
      if (prop === 'segments') {
        setValue('segments', product?.segments[0]?.parent_segment_id)
        setSelectedSegment(product?.segments[0]?.parent_segment_id)

        setSelectedSubSegment(product?.segments.map(seg => seg.id))
        continue
      }

      if (prop === 'brand') {
        setValue('brand_id', product?.brand?.id)
        continue
      }

      if (prop === 'fifo') {
        // eslint-disable-next-line
        if (product?.fifo?.expire_date) setValue('product_fifo.expire_date', utils.formatDate(product?.fifo?.expire_date, 'YYYY-MM-DD'))
        if (product?.fifo?.pack_number) setValue('product_fifo.pack_number', product?.fifo?.pack_number)
        if (product?.fifo !== null) setIsFifo(true)
        continue
      }

      //@ts-ignore
      setValue(prop, product[prop])
    }
  }

  function handleChangePrices(value: string | number, type: string) {
    setPrices(prevState => ({ ...prevState, [type]: value }))
  }

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const maximumSize = 4 * 1024 * 1024

    const selectedImages = Array.from(event.target.files)

    const [selectedImagesPreview] = selectedImages?.map(image => {
      if (image.type !== 'image/jpg' && image.type !== 'image/jpeg') {
        utils.toastify('A imagem deve ser no formato .jpg ou .jpeg', 'error')
        return ''
      }

      if (image.size >= maximumSize) {
        utils.toastify('Insira uma imagem menor que 4MB', 'error')
        return ''
      }

      return URL.createObjectURL(image)
    })

    setPreviewImage(selectedImagesPreview)
  }

  const handleGetCropData = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper

    if (typeof cropper !== 'undefined') {
      const fileBase64 = cropper.getCroppedCanvas().toDataURL()

      const newArrayPreview = Array.isArray(imagesPreview) ? [...imagesPreview, fileBase64] : [fileBase64]

      setImagesPreview(newArrayPreview)
      setPreviewImage(undefined)
      setOpenModal(false)
    }
  }

  const handleRemoveImage = (url: string) => {
    const imagesFiltered = imagesPreview?.filter(image => image !== url)
    setImagesPreview(imagesFiltered)
  }

  const handleChangeSegment = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSegment(event.target.value)
  }, [])

  const handleMultiSelectChange = useCallback((selectedValues: any) => {
    setSelectedSubSegment(selectedValues);
  }, [setSelectedSubSegment]);



  //Atualiza a lista de subcategorias de acordo com a categoria principal
  useEffect(() => {
    if (selectedSegment) {
      const filteredSubsegments = subSegmentsList.filter(item => item.parent_id === selectedSegment)

      setFilteredSubSegmentList(filteredSubsegments)
    }
  }, [selectedSegment])

  //Se o produto estiver em edição, atualiza os inputs com os valores do produto existente
  useEffect(() => {
    if (isEditing)
      setValue('subsegments', selectedSubSegment)
  }, [filteredSubSegmentsList, isEditing])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Components.Modal title="Inserir imagem do produto" setIsOpen={setOpenModal} isOpen={openModal}>
        <ModalContainer>
          <div className="box__image">
            <Cropper style={{ height: '40rem', width: '40rem' }} src={previewImage} ref={cropperRef} aspectRatio={1} />

            <input onChange={handleChangeImage} accept="image/jpg, image/jpeg" id="image[]" type="file" />

            <div className="box__options">
              <label htmlFor="image[]" className="link">
                inserir nova imagem
              </label>

              <button type="button" className="link" onClick={handleGetCropData}>
                Salvar alteração
              </button>
            </div>
          </div>
        </ModalContainer>
      </Components.Modal>
      <Container onSubmit={handleSubmit(handleUpdateProduct)}>
        <header>
          <div className="header__left">
            <Components.ButtonBack customStyles={{ marginRight: '1.6rem' }} />
            <h1>Cadastro de produto</h1>
          </div>

          <Components.Button type="submit">Salvar produto</Components.Button>
        </header>

        <main>
          <Components.CardBox
            customStyles={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumn: '1/3', gridGap: '1.6rem' }}
            label="Detalhes do produto"
          >
            <Components.Input
              label="NOME DO PRODUTO"
              defaultValue={product?.name}
              disabled
              title="Impossível editar o campo selecionado. Para edição acessar o painel administrativo."
            />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1.6rem' }}>
              <Components.Input label="SKU DO PRODUTO" defaultValue={product?.sku} disabled />
              <Components.Input label="CÓDIGO DO FORNECEDOR" defaultValue={product?.supplier_code} disabled />
            </div>

            <Components.TextArea
              customStyles={{ gridColumn: '1/3' }}
              defaultValue={product?.description}
              label="DESCRIÇÃO DO PRODUTO"
              disabled
            />

            <Components.Select
              label="MARCA"
              placeholder="Selecione"
              suggestions={brandsList}
              error={errors.brand_id}
              register={register}
              name="brand_id"
            />

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '1.6rem'
              }}
            >
              <Components.Select
                label="CATEGORIA"
                placeholder="Selecione"
                suggestions={segmentsList}
                error={errors.segments}
                register={register}
                name="segments"
                onChange={handleChangeSegment}
              />
              <Components.MultiSelect
                label="SUBCATEGORIA"
                placeholder='Selecione'
                suggestions={filteredSubSegmentsList.map(item => ({
                  label: item.description,
                  value: item.id
                }))}
                error={errors.subsegments}
                setValue={setValue}
                onSelectChange={handleMultiSelectChange}
                name="subsegments"
                control={control}
                disabled={!selectedSegment && !selectedSubSegment}
              />
            </div>

            <Components.Input
              defaultValue={prices?.product_price ? utils.formatPrice(prices?.product_price) : undefined}
              label="VALOR"
              disabled
            />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1.6rem' }}>
              <Components.Input label="CÓDIGO DE BARRAS EAN13" defaultValue={product?.ean13_code} disabled />
              <Components.Input label="CÓDIGO DE BARRAS DUN14" defaultValue={product?.dum14_code} disabled />
            </div>

            <Components.Input label="QUANTIDADE MÍNIMA POR PEDIDO" defaultValue={product?.minimun_order} disabled />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1.6rem' }}>
              <Components.CheckBox checked={product?.metrics?.is_commodity || false}>
                Este produto é um Commodity
              </Components.CheckBox>

              <Components.CheckBox setState={setIsFifo} checked={isFifo}>
                Este produto é um FIFO
              </Components.CheckBox>
            </div>

            {isFifo && (
              <>
                <div className="divider" />

                <div
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '1.6rem', gridColumn: '1/3' }}
                >
                  <Components.Input
                    label="VALIDADE DO PRODUTO"
                    name="product_fifo.expire_date"
                    type="date"
                    error={errors?.product_fifo?.expire_date}
                    register={register}
                  />

                  <Components.Input
                    label="VALOR PROMOCIONAL"
                    placeholder="Informe o valor promocional do produto"
                    onChange={event => handleChangePrices(event.target.value, 'fifo_price')}
                    value={prices?.fifo_price ? utils.formatPrice(prices?.fifo_price) : undefined}
                    name="product_fifo.value"
                    error={errors?.product_fifo?.value}
                    register={register}
                  />

                  <Components.Input
                    label="NUMERO DO LOTE"
                    placeholder="Selecione"
                    name="product_fifo.pack_number"
                    error={errors?.product_fifo?.pack_number}
                    register={register}
                  />
                </div>
              </>
            )}
          </Components.CardBox>

          <Components.CardBox
            customStyles={{ paddingBottom: '3.2rem', marginTop: '3.2rem', gridColumn: '1/3' }}
            label="Imagem do produto"
          >
            <div className="images__container">
              {imagesPreview?.map(image => (
                <div key={image} className="box__image">
                  <img src={image} alt="" />

                  <button type="button" onClick={() => handleRemoveImage(image)}>
                    <UilTrashAlt color="white" size="1.2rem" />
                  </button>
                </div>
              ))}

              <div onClick={() => setOpenModal(true)} className="box__spam">
                <UilPlus color="grey" size="1.6rem" />
                <p>Adicione ou arraste a imagem do produto!</p>
              </div>
            </div>
          </Components.CardBox>

          <Components.CardBox
            customStyles={{
              gridTemplateColumns: '1fr 1fr',
              marginTop: '3.2rem',
              gridColumn: '1/3',
              gridGap: '1.6rem',
              display: 'grid'
            }}
            label="Unidades de medida do produto"
          >
            <Components.Select label="DESCRIÇÃO" value={product?.measureUnit?.id} suggestions={measureList} disabled />
            <Components.Input label="SÍMBOLO" defaultValue={product?.measureUnit?.unit_symbol} disabled />
            <Components.Input label="ALTURA (cm)" defaultValue={product?.metrics?.height} disabled />
            <Components.Input label="LARGURA (cm)" defaultValue={product?.metrics?.width} disabled />
            <Components.Input label="COMPRIMENTO (cm)" defaultValue={product?.metrics?.length} disabled />
            <Components.Input label="PESO BRUTO (g)" defaultValue={product?.metrics?.gross_weight} disabled />
            <Components.Input label="PESO LÍQUIDO (g)" defaultValue={product?.metrics?.net_weight} disabled />
          </Components.CardBox>

          <Components.CardBox
            customStyles={{
              gridTemplateColumns: 'repeat(4, 1fr)',
              marginTop: '3.2rem',
              gridColumn: '1/3',
              gridGap: '1.6rem',
              display: 'grid'
            }}
            label="Tipo de entrega"
          >
            <Components.CheckBox checked={product?.metrics?.is_product_refrigerated || false}>
              Produto refrigerado
            </Components.CheckBox>
            <Components.CheckBox checked={product?.metrics?.is_storage || false}>Armazenamento</Components.CheckBox>
            <Components.CheckBox checked={product?.metrics?.is_collect || false}>Coleta</Components.CheckBox>
            <Components.CheckBox checked={product?.metrics?.is_picking || false}>Picking</Components.CheckBox>
          </Components.CardBox>

          <Components.Button
            customStyles={{ margin: '3.2rem auto', gridColumn: '1/3' }}
            disabled={isUpdating}
            type="submit"
          >
            Salvar produto
          </Components.Button>
        </main>
      </Container>
    </>
  )
}

export { ProductsHandle }
