export const logoVa =
  'https://firebasestorage.googleapis.com/v0/b/varejo-aqui.appspot.com/o/default-images%2FlogoVA.svg?alt=media&token=54496cf0-72bd-4c89-af80-8b87a630e265'

export const ORDER_STATUS_SUGGESTIONS = [
  { value: 'payment_processing', text: 'Processando pagamento' },
  { value: 'payment_trouble', text: 'Problema no pagamento' },
  { value: 'in_route', text: 'Pedido em transporte' },
  { value: 'analysing', text: 'Analisando pedido' },
  { value: 'delivered', text: 'Pedido recebido' },
  { value: 'canceled', text: 'Pedido cancelado' },
  { value: 'invoiced', text: 'Pedido faturado' },
  { value: 'approved', text: 'Pedido aprovado' },
  { value: 'refused', text: 'Pedido recusado' },
  { value: 'late', text: 'Pedido atrasado' },
  { value: 'trouble', text: 'Dificuldade' },
  { value: 'done', text: 'Pedido feito}' }
]

export const ORDER_STATUS = {
  payment_processing: 'Processando pagamento',
  payment_trouble: 'Problema no pagamento',
  waiting_accept: 'Esperando aceite',
  out_delivery: 'Saiu pra entrega',
  in_route: 'Pedido em transporte',
  processing: 'Em processamento',
  trouble: 'Pedido com problema',
  delivered: 'Pedido entregue',
  canceled: 'Pedido cancelado',
  invoiced: 'Pedido faturado',
  approved: 'Pedido aprovado',
  refused: 'Pedido recusado',
  changed: 'Pedido alterado',
  done: 'Pedido finalizado',
  late: 'Pedido atrasado',
  separating: 'Separando',
  collected: 'Coletado',
  received: 'Recebido',
  packed: 'Embalado'
} as any
