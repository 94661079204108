import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import iconFood from '../../assets/images/iconFood.svg'
import { Autocomplete, Button, CardBox, Input, Loading, Paginate } from '../../components'
import { ISuggestions } from '../../components/Autocomplete'
import { routesPrivate } from '../../routes/routesLink'
import { BrandsGetSimpleList } from '../../services/Brands'
import { ProductsList } from '../../services/Products'
import { SegmentsSimpleList } from '../../services/Segments'
import { formatDate, formatPrice, toastify } from '../../utils'
import { Container } from './styles'

const Products: React.FC = () => {
  const { isLoading, isFetched, data, refetch } = useQuery('products', fetchData)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams({
    pageId:'1'
  });
  const pageId = searchParams.get('pageId');

  const [params, setParams] = useState({ pageOffset: 6, page: 1, is_active: true })
  const [segments, setSegments] = useState<ISuggestions[]>([])
  const [brands, setBrands] = useState<ISuggestions[]>([])
  const [segmentId, setSegmentId] = useState()
  const [brandId, setBrandId] = useState()

  async function fetchData() {
    try {
      if(pageId && !isNaN(parseInt(pageId))){
        params.page = parseInt(pageId);
        setParams(prevState => ({ ...prevState, page: parseInt(pageId) }))
      }
      if (!isFetched) {
        const segmentsResponse = await SegmentsSimpleList()
        const brandsResponse = await BrandsGetSimpleList()

        const segmentsFormatted: ISuggestions[] = []
        segmentsResponse.map(item => {
          item.subsegments.map(subsegment => segmentsFormatted.push({
            text: subsegment.description,
            value: subsegment.id
          }))
        })
        const brandsFormatted = brandsResponse.map(brand => ({ text: brand.name, value: brand.id }))

        setSegments(segmentsFormatted)
        setBrands(brandsFormatted)
      }

      Object.assign(params, { segment_id: segmentId, brand_id: brandId })

      return await ProductsList(params)
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregar produtos', 'error')
    }
  }

  function handleShowProduct(product: any) {
    navigate(routesPrivate.productsEdit, { state: { product } })
  }

  function handleChangeState(event: any) {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
  }

  const filterActiveProducts = () => {
    setParams(prevState => ({ ...prevState, is_active: !prevState.is_active }))
  }

  const handlePreviousPage = () => {
    setSearchParams({pageId: (params.page - 1).toString()})
    setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  }
  const handleNextPage = () => {
    setSearchParams({pageId: (params.page + 1).toString()})
    setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))
  }

  useEffect(() => {
    refetch()
  }, [params.page, params.is_active])

  return (
    <Container>
      <header>
        <div className="box__title">
          <h1>Meus produtos</h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px'
            }}
          >
            <Button onClick={filterActiveProducts}>
              {params.is_active ? 'Mostrar todos os produtos' : 'Mostrar somente ativos'}
            </Button>

            {/* <Link to={routesPrivate.productsCreate}>
              <Button>Adicionar novo produto</Button>
            </Link> */}
          </div>
        </div>

        <div className="box__search">
          <Input iconSearch placeholder="Pesquisar por produto" onChange={handleChangeState} name="name" />

          <Autocomplete
            placeholder="Meu segmentos ativos"
            suggestions={segments}
            setState={setSegmentId}
            name="segment_id"
          />

          <Autocomplete
            placeholder="Todos as marcas"
            setState={setBrandId}
            suggestions={brands}
            name="brand_id"
          />

          <Button onClick={() => refetch()} customStyles={{ marginTop: '2.4rem' }}>
            Pesquisar
          </Button>
        </div>
      </header>

      <main>
        <div className="title__list">
          <span>Nome do produto</span>
          <span>SKU</span>
          <span>Validade</span>
          <span>Lote</span>
          <span>Preço</span>
          <span>Marcas</span>
          <span>Segmento</span>
          <span>Tipo de unidade</span>
          <span>Status</span>
        </div>

        {isLoading && <Loading style={{ margin: '0 auto' }} />}

        {isFetched && (
          <>
            <CardBox customStyles={{ padding: 0 }}>
              {data?.results?.map(product => (
                <ul key={product?.id}>
                  <li onClick={() => handleShowProduct(product)}>
                    <span>
                      <img src={product?.images[0] || iconFood} alt="" />
                      {product?.name}
                    </span>
                    <span>{product?.sku}</span>
                    <span>{formatDate(product?.expire_date)}</span>
                    <span>{product?.pack_number}</span>
                    <span>{formatPrice(product?.value)}</span>
                    <span>{product?.brand}</span>
                    <span>{product?.segments?.map(segment => segment)}</span>
                    <span>{product?.measure_unit}</span>
                    <span>{product?.is_active ? 'Ativo' : 'Inativo'}</span>

                    <button className="link" onClick={() => handleShowProduct(product)}>
                      ver detalhes
                    </button>
                  </li>
                </ul>
              ))}
            </CardBox>

            <Paginate
              customStyles={{ position: 'fixed', bottom: '1.6rem' }}
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              currentPage={params?.page || 1}
            />
          </>
        )}
      </main>
    </Container>
  )
}

export { Products }
