import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useParams } from 'react-router-dom'

import { UilStar } from '@iconscout/react-unicons'

import { Button, ButtonBack, CardBox, Input, Loading, Paginate } from '../../../components'
import { routesPrivate } from '../../../routes/routesLink'
import { GetOneRetailers } from '../../../services/Retailers'
import { formatDate, toastify } from '../../../utils'
import { Container } from './styles'

import { ORDER_STATUS } from '../../../constants'

const RetailersOrderHistory: React.FC = () => {
  const { retailer_id } = useParams<'retailer_id'>()
  const { isLoading, isFetched, data, refetch } = useQuery(`retailers/${retailer_id}`, fetchData)
  const { state }: any = useLocation()

  const [params, setParams] = useState({ pageOffset: 6, page: 1 })

  async function fetchData() {
    try {
      if (retailer_id) return await GetOneRetailers({ retailer_id, params })
    } catch (error) {
      toastify('Erro ao carregar o histórico de pedidos', 'error')
    }
  }

  function handleChangeState(event: any): void {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, [name]: value || undefined }))
  }

  function handleKeyDown(event: any): void {
    if (event.key !== 'Enter') return
    refetch()
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params.page])

  return (
    <Container>
      <header>
        <div className="header__left">
          <ButtonBack customStyles={{ marginRight: '1.6rem' }} />
          <h1>Histórico de pedidos</h1>
          <span>{state?.retailer?.retailer_company_name}</span>
        </div>

        <div className="box__search">
          <Input
            placeholder="Busque pelo número do pedido..."
            onChange={handleChangeState}
            onKeyDown={handleKeyDown}
            name="order_number"
            iconSearch
          />

          <Button onClick={fetchData} customStyles={{ marginLeft: '1.6rem', marginTop: '2.4rem' }}>
            Pesquisar
          </Button>
        </div>
      </header>

      <main>
        <div className="title__list">
          <span>Data</span>
          <span>Pedido</span>
          <span>Status</span>
          <span>Qnt. de produtos</span>
          <span>Data estimada de entrega</span>
          <span>Nota do pedido</span>
        </div>

        {isLoading && <Loading style={{ margin: '0 auto' }} />}

        {isFetched && (
          <>
            <CardBox customStyles={{ padding: 0 }}>
              <ul>
                {data?.results?.map(order => (
                  <li key={order?.order_id}>
                    <span>{formatDate(order?.order_created_at)}</span>
                    <span>{order?.order_order_number}</span>
                    <span>{ORDER_STATUS[order?.order_status]}</span>
                    <span>{order?.total_products}</span>
                    <span>{formatDate(order?.shipment_estimated_date)}</span>

                    <span>
                      <UilStar />
                      {order?.review_score}
                    </span>

                    <Link className="link centralized" to={routesPrivate.retailersOrderDetails + order.order_id}>
                      ver detalhes
                    </Link>
                  </li>
                ))}
              </ul>
            </CardBox>

            <Paginate
              customStyles={{ position: 'fixed', bottom: '1.6rem' }}
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              currentPage={params.page}
            />
          </>
        )}
      </main>
    </Container>
  )
}

export { RetailersOrderHistory }
