import React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import iconFood from '../../assets/images/iconFood.svg'
import { CardBox, Loading } from '../../components'
import { routesPrivate } from '../../routes/routesLink'
import { GeneralBestSellers, GeneralPendingOrders, GeneralRanking, GeneralSalesSummary } from '../../services/General'
import { formatDate, formatPrice, toastify } from '../../utils'
import { Container } from './styles'

import { PAYMENT_METHOD } from '../../constants'

const Dashboard: React.FC = () => {
  const { isLoading, isFetched, data } = useQuery('dashboard', fetchData)

  async function fetchData() {
    try {
      const pending_orders = await GeneralPendingOrders({ pageOffset: 4 })
      const best_sellers = await GeneralBestSellers({ pageOffset: 4 })
      const ranking = await GeneralRanking({ itensOffset: 5 })
      const sales_summary = await GeneralSalesSummary()

      return { pending_orders, sales_summary, best_sellers, ranking }
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregas os dados dessa tela', 'error')
    }
  }

  return (
    <>
      {isLoading && <Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <h1>Pedidos em andamento</h1>

            <Link className="link" to={routesPrivate.orders}>
              Ver todos os pedidos
            </Link>
          </header>

          <main>
            <section className="orders__in__progress">
              {data?.pending_orders?.results?.map(item => (
                <CardBox key={item.id}>
                  <main>
                    <div className="order__column">
                      <strong>Nº {item?.order_number}</strong>
                      <span>Qnt. de Produtos</span>
                      <strong>{item?.total_items}</strong>
                      <span>Forma de Pagto.</span>
                      <strong>{PAYMENT_METHOD[item?.payment_method]}</strong>
                    </div>

                    <div className="order__column">
                      <span>Data de entrega desejada</span>
                      <strong>{formatDate(item?.estimated_date)}</strong>
                      <span>Valor total</span>
                      <strong>{formatPrice(item?.value)}</strong>
                    </div>
                  </main>

                  <footer>
                    <Link className="link centralized" to={routesPrivate.ordersDetails + item.id}>
                      Ver pedido
                    </Link>
                  </footer>
                </CardBox>
              ))}
            </section>

            <section className="statistics">
              <div className="statistics__left">
                <h3>Lucro do mês</h3>

                <CardBox>
                  <span>Até o momento</span>
                  <br />
                  <strong>{formatPrice(data?.sales_summary?.sold)}</strong>
                </CardBox>

                <h3>Pedidos finalizados</h3>

                <CardBox>
                  <span>Total</span>
                  <br />
                  <strong>{data?.sales_summary?.orders_done}</strong>
                </CardBox>
              </div>

              <div className="statistics__right">
                <header>
                  <h3>Produtos mais vendidos</h3>

                  <Link className="link" to={routesPrivate.products}>
                    Ver todos
                  </Link>
                </header>

                <main>
                  {data?.best_sellers?.results?.map(item => (
                    <CardBox key={item.itens_product_id} className="statistics__product">
                      <img src={item.image || iconFood} alt={item.product_name} />
                      <strong>{item.product_name}</strong>
                    </CardBox>
                  ))}
                </main>
              </div>
            </section>

            <section className="ranking__list">
              <h3>Ranking por</h3>

              <main>
                <CardBox className="ranking">
                  <header>
                    <div>
                      <h4>Varejistas</h4>
                      <p>Nome do Varejo</p>
                    </div>

                    <p>Total em pedidos</p>
                  </header>

                  <ul>
                    {data?.ranking?.retailers_ranking?.map((item, index) => (
                      <li key={item.retailer_id}>
                        <strong>
                          <span className="circle">{index + 1}</span>
                          {item.retailer_name}
                        </strong>
                        <p>{formatPrice(item.total_paid)}</p>
                      </li>
                    ))}
                  </ul>
                </CardBox>

                <CardBox className="ranking">
                  <header>
                    <div>
                      <h4>Cidade</h4>
                      <p>Nome da cidade</p>
                    </div>

                    <p>Número de vendas</p>
                  </header>

                  <ul>
                    {data?.ranking?.cities_ranking?.map((item, index) => (
                      <li key={item.city_id}>
                        <strong>
                          <span className="circle">{index + 1}</span>
                          {item.city_name}
                        </strong>
                        <p>{item.total_city_done}</p>
                      </li>
                    ))}
                  </ul>
                </CardBox>

                <CardBox className="ranking">
                  <header>
                    <div>
                      <h4>Estado</h4>
                      <p>Nome do estado</p>
                    </div>

                    <p>Número de vendas</p>
                  </header>

                  <ul>
                    {data?.ranking?.states_ranking?.map((item, index) => (
                      <li key={item.state_id}>
                        <strong>
                          <span className="circle">{index + 1}</span>
                          {item.state_name}
                        </strong>
                        <p>{item.total_state_done}</p>
                      </li>
                    ))}
                  </ul>
                </CardBox>
              </main>
            </section>
          </main>
        </Container>
      )}
    </>
  )
}

export { Dashboard }
