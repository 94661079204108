import { AxiosRequestConfig } from 'axios'

export function logRequest(request: AxiosRequestConfig): void {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    console.group('Request')

    console.log(`[${request.method?.toLocaleUpperCase()}] ${request.url}`)
    if (request.method !== 'get' && request.method !== 'delete') console.log(request?.data)

    console.groupEnd()
  }
}
