import styled from 'styled-components'

interface IContainerProps {
  neutralTemplate?: boolean
  clearTemplate?: boolean
  customStyles?: any
}

export const Container = styled.button<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(180deg, #0e1f2b 0%, #2d4759 11.77%, #0e1f2b 32.6%, #152835 62.81%, #5f88a5 100%);
  color: var(--color-white);
  border-radius: 1.4rem;
  padding: 0 3.2rem;
  transition: 0.5s;
  min-width: 16rem;
  cursor: pointer;
  height: 4.4rem;
  border: none;

  :hover {
    opacity: 0.7;
  }

  ${({ clearTemplate }) =>
    clearTemplate &&
    'background: linear-gradient(180deg, #EDE5AD 0%, #FDFBCF 18.24%, #EBE2A9 45.36%, #EEE6B0 57.5%, #FDFBCF 94.26%);\ncolor: black;'}

  ${({ neutralTemplate }) =>
    neutralTemplate &&
    'background: linear-gradient(180deg, #E6EBEF 0%, #FFFFFF 11.25%, #E2E5E8 33.65%, #E8EEF3 62.81%, #FFFFFF 100%);\ncolor: black;'}

  ${({ customStyles }) => customStyles}
`
