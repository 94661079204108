const convertBase64ToFile = (base64: string, contentType = 'image/jpg', chunkLength = 512): File | undefined => {
  if (!base64) return undefined

  const byteCharsArray = Array.from(atob(base64.substr(base64.indexOf(',') + 1)))
  const chunksIterator = new Array(Math.ceil(byteCharsArray.length / chunkLength))
  const bytesArrays = []

  for (let c = 0; c < chunksIterator.length; c++) {
    bytesArrays.push(
      new Uint8Array(byteCharsArray.slice(c * chunkLength, chunkLength * (c + 1)).map(s => s.charCodeAt(0)))
    )
  }

  return new File(bytesArrays, 'filename', { type: contentType })
}

export { convertBase64ToFile }
