import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-primaryOne: #091A27;
    --color-primaryTwo: #FFE999;
    --color-secondaryOne: #0D2230;
    --color-secondaryTwo: #FFF6D6;
    --color-background: #FFFDF6;
    --color-orange: #F2994A;
    --color-button: #1F3545;
    --color-green: #219653;
    --color-white: #FFFFFF;
    --color-grey: #3D5B6F;
    --color-blue: #2F80ED;
    --color-pink: #CE4361;
  }

  html {
    font-family: 'Roboto', sans-serif;
    font-family: 'Sora', sans-serif;
    font-size: 68.5%;

    body {
      font-size: 1.6rem;
      height: 100vh;
      width: 100vw;
      padding: 0;
      margin: 0;

      .filepond--credits {
        display: none;
      }

      main {
        display: block;
      }

      h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
        margin: 0.67em 0;
      }

      hr {
        box-sizing: content-box;
        overflow: visible;
        height: 0;
      }

      pre {
        font-family: monospace, monospace;
        font-size: 1em;
      }

      ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      a {
        background-color: transparent;
        text-decoration: none;
        color: black;

        &:visited {
          text-decoration: none;
          color: black;
        }
      }

      .link {
        text-decoration: underline;
        color: var(--color-blue);
        background: transparent;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
        border: none;

        &:visited {
          color: var(--color-blue);
          text-decoration: none;
        }
      }

      abbr[title] {
        text-decoration: underline dotted;
        text-decoration: underline;
        border-bottom: none;
      }

      b, strong {
        font-weight: bolder;
      }

      code, kbd, samp {
        font-family: monospace, monospace;
        font-size: 1em;
      }

      small {
        font-size: 80%;
      }

      sub, sup {
        vertical-align: baseline;
        position: relative;
        line-height: 0;
        font-size: 75%;
      }

      sub {
        bottom: -0.25em;
      }

      sup {
        top: -0.5em;
      }

      img {
        border-style: none;
      }

      button, input, optgroup, select, textarea {
        line-height: 1.15;
        font-size: 100%;
        margin: 0;
      }

      button, input {
        overflow: visible;
      }

      button, select {
        text-transform: none;
      }

      button, [type='button'], [type='reset'], [type='submit'] {
        -webkit-appearance: button;
      }

      button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner {
        border-style: none;
        padding: 0;
      }

      button:-moz-focusring, [type='button']:-moz-focusring, [type='reset']:-moz-focusring, [type='submit']:-moz-focusring {
        outline: 1px dotted ButtonText;
      }

      fieldset {
        padding: 0.35em 0.75em 0.625em;
      }

      legend {
        box-sizing: border-box;
        white-space: normal;
        max-width: 100%;
        display: table;
        color: inherit;
        padding: 0;
      }

      progress {
        vertical-align: baseline;
      }

      textarea {
        overflow: auto;
      }

      [type='checkbox'], [type='radio'] {
        box-sizing: border-box;
        padding: 0;
      }

      [type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button {
        height: auto;
      }

      [type='search'] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
      }

      [type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
      }

      ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
      }

      details {
        display: block;
      }

      summary {
        display: list-item;
      }

      template {
        display: none;
      }

      [hidden] {
        display: none;
      }

      [disabled] {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }

  *::-webkit-scrollbar {
    height: 0.6rem;
    width: 0.6rem;
  }

  *::-webkit-scrollbar-track {
    background: #091A27;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2rem;
  }

  @media (max-width: 1664px) {
    html {
      font-size: 60%;
    }
  }

  @media (max-width: 1260px) {
    html {
      font-size: 53%;
    }
  }

  @media (max-width: 1200px) {
    html {
      font-size: 47%;
    }
  }
`
