import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;

  font-size: 1.4rem;
  font-weight: 300;
  height: 8.5rem;

  ${({ customStyles }) => customStyles}

  .box__input {
    display: flex;
    align-items: center;

    box-shadow: inset 0 0.5rem 1rem #00000020;
    border: 0.1rem solid #e6ebef;
    padding: 0.8rem 1.6rem;
    border-radius: 1rem;
    margin: auto 0 0 0;
    background: white;

    select {
      color: var(--color-grey);
      background: transparent;
      outline: none;
      border: none;
      height: 2rem;
      width: 100%;
    }
  }

  span {
    color: var(--color-pink);
    margin-top: 0.4rem;
    font-size: 1.2rem;
    height: 1.2rem;
  }
`
