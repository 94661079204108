import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;
  }

  main {
    .others__accounts,
    .main__account {
      margin-top: 6.4rem;

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`

export const ConfirmaModal = styled.div`
  p {
    color: var(--color-grey);
    margin-bottom: 6.4rem;
  }

  .box__options {
    display: flex;
    justify-content: space-between;
  }
`
