import * as yup from 'yup'

export const schemeForgotPassword = yup.object().shape({
  email: yup.string().required('Campo obrigatório').email('Você precisa inserir um email válido.')
})

export const schemeChangePassword = yup.object().shape({
  email: yup.string().required('Campo obrigatório').email('Você precisa inserir um email válido.'),
  code: yup.number().required('Campo obrigatório').typeError('Informe somente números'),
  password: yup.string().required('Campo obrigatório')
})
