import React from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import { UilSearch } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.InputHTMLAttributes<any> {
  customStyles?: React.CSSProperties
  iconSearch?: boolean
  label?: string
  mask: string

  register?: UseFormRegister<FieldValues>
  error?: FieldError
}

const InputMask: React.FC<IProps> = ({ customStyles, iconSearch, register, error, label, mask, ...rest }) => (
  <Container customStyles={customStyles}>
    {label}

    <div className="box__input">
      {iconSearch && <UilSearch color="var(--color-secondaryOne)" />}

      {register && rest.name ? (
        <ReactInputMask {...register(rest.name)} mask={mask} {...rest} />
      ) : (
        <ReactInputMask mask={mask} {...rest} />
      )}
    </div>

    <span>{!!error && error.message}</span>
  </Container>
)

export { InputMask }
