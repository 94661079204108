import ApiClient from '../ApiClient'
import { ILoginPayload, ILoginResponse, IRefreshTokenFirebaseResponse } from './interfaces'

const routeRefresh = '/api/refresh_token/'
const routeSession = '/api/session/'

const RefreshTokenFirebase = (): Promise<IRefreshTokenFirebaseResponse> => ApiClient.api.get(routeRefresh + 'firebase')
const RefreshToken = (token: string): Promise<ILoginResponse> => ApiClient.api.get(routeRefresh + token)

const SessionIndustry = (payload: ILoginPayload): Promise<ILoginResponse> =>
  ApiClient.api.post(routeSession + 'industry', payload)

export { RefreshTokenFirebase, RefreshToken, SessionIndustry }
