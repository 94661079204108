import ApiClient from '../ApiClient'
import {
  IGetRetailersMeanInvoices,
  IFinancialHistoryParams,
  IAccumulatedInvoicing,
  IGetFinancialHistory,
  IGetNetInvoicing,
  IForecastValues,
  IDepositDetail,
  IRates
} from './interfaces'

const route = '/api/industries/financial/'

const GetDepositDetail = (id: string): Promise<IDepositDetail> => ApiClient.api.get(route + 'histories/' + id)
const GetForecastValues = (): Promise<IForecastValues> => ApiClient.api.get(route + 'histories/')
const GetNetInvoicing = (): Promise<IGetNetInvoicing> => ApiClient.api.get(route + 'net_invoicing')
const GetRates = (): Promise<IRates[]> => ApiClient.api.get(route + 'rates_by_methods')

const GetFinancialHistory = (params?: IFinancialHistoryParams): Promise<IGetFinancialHistory> =>
  ApiClient.api.get(route + 'histories', { params })

const GetRetailersMeanInvoices = (): Promise<IGetRetailersMeanInvoices> =>
  ApiClient.api.get(route + 'retailer_avg_invoices')

const GetAccumulatedInvoicing = (): Promise<IAccumulatedInvoicing[]> =>
  ApiClient.api.get(route + 'accumulated_invoicing')

export {
  GetRetailersMeanInvoices,
  GetAccumulatedInvoicing,
  GetFinancialHistory,
  GetForecastValues,
  GetDepositDetail,
  GetNetInvoicing,
  GetRates
}
