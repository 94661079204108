import React from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { UilShieldCheck } from '@iconscout/react-unicons'

import { Button, ButtonBack, InputPassword } from '../../../components'
import { useAuth } from '../../../hooks/useAuth'
import { PatchIndustry } from '../../../services/Industry'
import { toastify } from '../../../utils'
import { Container } from './styles'

import { schemeSettingsChangePassword } from './schemeSettingsChangePassword'

const SettingsChangePassword: React.FC = () => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(schemeSettingsChangePassword) })
  const { errors } = formState
  const { user } = useAuth()

  async function handleChangePassword(event: any): Promise<void> {
    const { password, old_password } = event

    try {
      if (user) {
        await PatchIndustry({ industry_id: user?.id, payload: { password, old_password } })
        toastify('Senha alterada com sucesso!', 'success')
      }
    } catch (error) {
      toastify('Erro ao tentar alterar senha', 'error')
    }
  }

  return (
    <Container>
      <header>
        <ButtonBack />
        <h1>Alterar senha</h1>
      </header>

      <form onSubmit={handleSubmit(handleChangePassword)}>
        <InputPassword
          customStyles={{ marginBottom: '1.6rem' }}
          placeholder="Insira aqui sua senha atual"
          label="SENHA ATUAL"
          type="password"
          name="old_password"
          error={errors.old_password}
          register={register}
        />

        <InputPassword
          customStyles={{ marginBottom: '1.6rem' }}
          placeholder="Insira aqui sua nova senha"
          label="NOVA SENHA"
          type="password"
          name="password"
          error={errors.password}
          register={register}
        />

        <InputPassword
          customStyles={{ marginBottom: '1.6rem' }}
          placeholder="Repita a nova senha"
          label="CONFIRMAR NOVA SENHA"
          type="password"
          name="confirmPassword"
          error={errors.confirmPassword}
          register={register}
        />

        <p>
          <UilShieldCheck />
          Pelo menos 8 caracteres
        </p>

        <p>
          <UilShieldCheck />
          Insira um número (0 - 9)
        </p>

        <p>
          <UilShieldCheck />
          Insira uma letra maiúscula (A - Z)
        </p>

        <Button customStyles={{ margin: '0 auto', marginTop: '6.4rem' }} type="submit">
          Salvar alterações
        </Button>
      </form>
    </Container>
  )
}

export { SettingsChangePassword }
