import React from 'react'
import { useQuery } from 'react-query'

import * as Components from '../../../components'
import { useAuth } from '../../../hooks/useAuth'
import { GetIndustryAddress } from '../../../services/Address'
import { toastify } from '../../../utils'
import { Container } from './styles'

import { STATES_SUGGESTIONS } from '../../../constants'

const SettingsAddress: React.FC = () => {
  const { data } = useQuery('industry-address', fetchData)
  const { user } = useAuth()

  async function fetchData() {
    if (!user) return

    try {
      return await GetIndustryAddress(user.id)
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregar o endereço', 'error')
    }
  }

  return (
    <Container>
      <header>
        <Components.ButtonBack />
        <h1>Endereço principal</h1>
      </header>

      <form>
        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          defaultValue={data?.address}
          value={data?.address}
          label="LOGRADOURO"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.address_number}
          label="NÚMERO"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.complement}
          label="COMPLEMENTO"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.neighborhood}
          label="BAIRRO"
          disabled
        />

        <Components.InputMask
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.zip_code}
          mask="99999-999"
          label="CEP"
          disabled
        />

        <Components.Input
          customStyles={{ marginBottom: '1.6rem' }}
          value={data?.city_name}
          label="MUNICÍPIO"
          disabled
        />

        <Components.Select
          customStyles={{ marginBottom: '1.6rem' }}
          suggestions={STATES_SUGGESTIONS}
          value={data?.state_short_name}
          label="ESTADO"
          disabled
        />
      </form>
    </Container>
  )
}

export { SettingsAddress }
