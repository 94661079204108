import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Button, CardBox, Input, Loading, Paginate } from '../../components'
import { routesPrivate } from '../../routes/routesLink'
import { GetAllRetailers } from '../../services/Retailers'
import { formatMask, formatPrice, toastify } from '../../utils'
import { Container } from './styles'

const Retailers: React.FC = () => {
  const { isLoading, isFetched, data, refetch } = useQuery('retailers', fetchData)
  const navigate = useNavigate()

  const [params, setParams] = useState({ pageOffset: 6, page: 1 })

  async function fetchData() {
    try {
      return await GetAllRetailers(params)
    } catch (error) {
      toastify('Não foi possível carregar os varejistas', 'error')
    }
  }

  function handleChangeState(event: any): void {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
  }

  function handleShowRetailer(retailer: any) {
    navigate(routesPrivate.retailersOrderHistory + retailer.retailer_id, { state: { retailer } })
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params.page])

  return (
    <Container>
      <header>
        <h1>Varejistas</h1>

        <div className="box__search">
          <Input
            placeholder="Buscar pelo nome do varejistas"
            onChange={handleChangeState}
            name="company_name"
            iconSearch
          />

          <Button onClick={() => refetch()} customStyles={{ marginLeft: '1.6rem', marginTop: '2.4rem' }}>
            Pesquisar
          </Button>
        </div>
      </header>

      <main>
        <div className="title__list">
          <span>Nome</span>
          <span>CNPJ</span>
          <span>Núm. de pedidos</span>
          <span>Total gasto</span>
          <span>Ciclo de compra</span>
          <span>Período da ultima compra</span>
        </div>

        {isLoading && <Loading style={{ margin: '0 auto' }} />}

        {isFetched && (
          <>
            <CardBox customStyles={{ padding: 0 }}>
              <ul>
                {data?.results?.map(retailer => (
                  <li key={retailer.retailer_id}>
                    <span>{retailer?.retailer_company_name}</span>
                    <span>{formatMask(retailer?.retailer_cnpj, '##.###.###/####-##')}</span>
                    <span>{retailer?.total}</span>
                    <span>{formatPrice(retailer?.total_paid)}</span>
                    <span>não se aplica</span>
                    <span>{retailer?.days_since_last_order} dias atrás</span>

                    <button className="link centralized" onClick={() => handleShowRetailer(retailer)} type="button">
                      ver detalhes
                    </button>
                  </li>
                ))}
              </ul>
            </CardBox>

            <Paginate
              customStyles={{ position: 'fixed', bottom: '1.6rem' }}
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              currentPage={params.page}
            />
          </>
        )}
      </main>
    </Container>
  )
}

export { Retailers }
