import ApiClient from '../ApiClient'
import {
  IGetAllRetailersResponse,
  IGetOneRetailersResponse,
  IGetAllRetailersParams,
  IGetOneRetailersParams
} from './interfaces'

const route = '/api/industries/retailers/'

const GetAllRetailers = (params: IGetAllRetailersParams): Promise<IGetAllRetailersResponse> =>
  ApiClient.api.get(route, { params })

const GetOneRetailers = ({ retailer_id, params }: IGetOneRetailersParams): Promise<IGetOneRetailersResponse> =>
  ApiClient.api.get(route + retailer_id, { params })

export { GetAllRetailers, GetOneRetailers }
