import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    font-weight: normal;
    margin: 0;
  }

  .search__options {
    display: flex;
    justify-content: space-between;
  }

  .container__segments {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25.6rem, 1fr));
    grid-gap: 1.6rem;

    margin-top: 3.2rem;

    .box__segment {
      display: flex;
      align-items: center;

      img {
        border: 0.1rem solid var(--color-primaryTwo);
        border-radius: 1.6rem;
        object-fit: cover;
        height: 6.4rem;
        width: 6.4rem;
      }

      .box__segment-content {
        margin-left: 1.6rem;

        h2 {
          font-size: 1.6rem;
          margin: 0;
        }

        span {
          font-size: 1.2rem;
        }
      }
    }
  }
`
