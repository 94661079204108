import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
  label?: boolean
}

export const Container = styled.div<IContainerProps>`
  padding: ${({ label }) => (label ? '6.4rem' : '1.6rem')} 1.6rem 1.6rem 1.6rem;
  border: 0.1rem solid var(--color-primaryTwo);
  border-radius: 0.8rem;
  position: relative;
  background: white;

  ${({ customStyles }) => customStyles}

  .header {
    position: absolute;

    color: var(--color-grey);
    font-size: 2.4rem;
    left: 1.6rem;
    top: 1.6rem;
  }
`
