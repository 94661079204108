import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: normal;
      margin: 0;
    }

    .box__search {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.6rem;
    }
  }

  main {
    margin-top: 6.4rem;

    .title__list {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 1.6rem;

      font-size: 1.2rem;
      padding: 1.6rem;
    }

    ul {
      li {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 1.6rem;

        border: solid 0.1rem transparent;
        font-weight: normal;
        font-size: 1.2rem;
        padding: 1.6rem;

        span {
          display: flex;
          align-items: center;

          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 12.8rem;
          overflow: hidden;

          svg {
            margin: 0 0.4rem 0 0;
            height: 1.6rem;
            color: #f2c931;
            width: 1.6rem;
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        &:hover {
          border: solid 0.1rem var(--color-blue);
          border-radius: 0.8rem;
          background: #2f80ed10;
        }
      }
    }
  }
`
