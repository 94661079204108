import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  main {
    .recipe__cards {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .recipe__cards-card {
        span {
          color: var(--color-grey);
          font-size: 1.2rem;
          font-weight: 300;
        }

        p {
          margin: 0.4rem 0 0 0;
          font-weight: bold;
          font-size: 1.4rem;
        }
      }

      svg {
        color: var(--color-primaryTwo);
        height: 4rem;
        width: 4rem;
      }
    }

    section {
      margin-top: 3.2rem;
    }

    .revenue__per__period {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      grid-gap: 1.6rem;
    }

    .graphical__statistics {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 1.6rem;

      .graphical__statistics-graphic {
      }
    }

    .box__footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.6rem;

      .box__footer-sections {
        .box__footer-cards {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
          grid-gap: 1.6rem;
        }
      }
    }
  }
`
