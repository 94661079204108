import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;

    h1 {
      margin: 0 0 0 1.6rem;
      font-weight: normal;
    }
  }

  form {
    max-width: 51.2rem;
    padding: 6.4rem 0;
    margin: 0 auto;

    .divider {
      margin-bottom: 2.4rem;
      background: #00000010;
      height: 0.1rem;
      width: 100%;
    }
  }
`
