import styled from 'styled-components'

export const Container = styled.div`
  .box__image {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }

    .box__options {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      max-width: 25rem;
      width: 100%;

      label {
        margin-top: 1.6rem;

        img {
          border-radius: 1.6rem;
          object-fit: cover;
          cursor: pointer;
          height: 20rem;
          width: 20rem;
        }
      }
    }
  }
`
