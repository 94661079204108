import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'
import { PrivateRoutes } from './routes.private'
import { PublicRoutes } from './routes.public'

const Routes: React.FC = () => {
  const { signed } = useAuth()

  return <BrowserRouter>{signed ? <PrivateRoutes /> : <PublicRoutes />}</BrowserRouter>
}

export { Routes }
