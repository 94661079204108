import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__left {
      display: flex;
      align-items: center;

      h1 {
        font-weight: normal;
        margin: 0;
      }

      span {
        color: var(--color-grey);
        margin: auto 0 0 1.6rem;
        font-size: 1.4rem;
      }
    }
  }

  main {
    margin-top: 6.4rem;

    ul {
      li {
        display: grid;
        grid-template-columns: 12rem repeat(4, 1fr);
        grid-gap: 1.6rem;

        font-size: 1.4rem;

        img {
          border: 0.1rem solid var(--color-primaryTwo);
          border-radius: 0.8rem;
          object-fit: cover;
          padding: 1.6rem;
          height: 8.8rem;
          width: 8.8rem;
        }

        div {
          display: flex;
          justify-content: center;
          flex-direction: column;

          p {
            margin: 0;
          }
        }

        &:not(:first-child) {
          border-top: solid 0.1rem #21384810;
          padding-top: 1.6rem;
          margin-top: 1.6rem;
        }

        .margin-bottom {
          margin-bottom: 0.8rem;
        }

        .centralized {
          display: flex;
          align-items: center;
        }

        .fex-end {
          display: flex;
          align-items: end;
        }
      }
    }

    .divider {
      background: #00000010;
      margin: 3.2rem 0;
      height: 0.1rem;
      width: 100%;
    }
  }
`

export const ModalContainer = styled.div`
  main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.6rem;

    font-weight: 300;

    img {
      margin-right: 1.6rem;
      height: 9rem;
      width: 9rem;
    }

    .product__details {
      p {
        margin: 0 0 0.4rem 0;
        font-size: 1.2rem;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    margin: 3.2rem 0 1.6rem 0;
  }
`
