import { IBrands } from '../../types'
import ApiClient from '../ApiClient'
import {
  IBrandsSimpleListResponse,
  IIndustryUpdatePayload,
  IBrandsIndexResponse,
  IBrandsIndexParams
} from './interfaces'

const route = '/api/industry_brands/'

const BrandsGetSimpleList = (): Promise<IBrandsSimpleListResponse[]> => ApiClient.api.get(route + 'simple_list')
const BrandsIndustryCreate = (payload: IBrands): Promise<void> => ApiClient.api.post(route, payload)
const BrandsIndustryDelete = (id: string): Promise<IBrands> => ApiClient.api.delete(route + id)
const BrandsIndustryShow = (id: string): Promise<IBrands> => ApiClient.api.get(route + id)

const BrandsIndustryIndex = (params: IBrandsIndexParams): Promise<IBrandsIndexResponse> =>
  ApiClient.api.get(route, { params })

const BrandsIndustryUpdate = ({ id, payload }: IIndustryUpdatePayload): Promise<void> =>
  ApiClient.api.patch(route + id, payload)

export {
  BrandsIndustryCreate,
  BrandsIndustryDelete,
  BrandsIndustryUpdate,
  BrandsIndustryIndex,
  BrandsGetSimpleList,
  BrandsIndustryShow
}
