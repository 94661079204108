import React from 'react'

import { AnimatePresence, motion } from 'framer-motion'

import { UilTimes } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  customStyles?: React.CSSProperties
  isOpen: boolean
  title?: string
}

const Modal: React.FC<IProps> = ({ customStyles, setIsOpen, children, isOpen, title }) => {
  const variant = {
    containerExit: { opacity: 0, transition: { delay: 0.25 } },
    containerInitial: { opacity: 0 },
    containerAnimate: { opacity: 1 }
  }

  const modalVariant = {
    initial: {
      transition: { type: 'spring', duration: 0.25, damping: 10, delay: 0.1, mass: 2 },
      opacity: 0,
      y: 50
    },
    animate: {
      transition: { type: 'spring', duration: 0.25, damping: 10, delay: 0.1, mass: 2 },
      opacity: 1,
      y: 0
    }
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <Container
          customStyles={customStyles}
          animate="containerAnimate"
          initial="containerInitial"
          exit="containerExit"
          variants={variant}
        >
          <motion.div variants={modalVariant} initial="initial" animate="animate" exit="initial" className="modal">
            {title && (
              <header>
                <h4>{title}</h4>

                <button onClick={() => setIsOpen(false)}>
                  <UilTimes color="white" />
                </button>
              </header>
            )}

            <div className="children">{children}</div>
          </motion.div>
        </Container>
      ) : null}
    </AnimatePresence>
  )
}

export { Modal }
