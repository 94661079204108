import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import { firebaseStorage } from '../../services/firebaseClient'

import { refreshTokenFirebase } from './refreshTokenFirebase'

const uploadImages = async (files: unknown | File | Blob | (File | Blob)[]): Promise<string[]> => {
  if (!files) return []

  const storageName = process.env.NODE_ENV === 'development' ? 'images-dev' : 'images'
  const arrayFiles = Array.isArray(files) ? files : [files]
  const urlArray = []

  try {
    await refreshTokenFirebase()

    for await (const file of arrayFiles) {
      if (typeof file !== 'string') {
        const fileName = `${Date.now()}-${file.name.replaceAll(' ', '_')}`
        const storageRef = ref(firebaseStorage, `${storageName}/${fileName}`)

        await uploadBytes(storageRef, file)

        const url = await getDownloadURL(storageRef)
        urlArray.push(url)
      } else {
        urlArray.push(file)
      }
    }

    return urlArray
  } catch (error) {
    throw new Error('Ocorreu um no envio de imagens')
  }
}

export { uploadImages }
