import 'react-toastify/dist/ReactToastify.css'
import 'leaflet/dist/leaflet.css'

import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from './context/AuthContext'
import { Routes } from './routes'
import { queryClient } from './services/queryClient'
import { GlobalStyle } from './styles/global'

function App() {
  return (
    <>
      <GlobalStyle />

      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Routes />

          {process.env.REACT_APP_NODE_ENV === 'development' && <ReactQueryDevtools />}
        </QueryClientProvider>
      </AuthProvider>

      <ToastContainer />
    </>
  )
}

export default App
