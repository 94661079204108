import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(135deg, #fffdf6 80%, #fdfbcf 20%, #fffdf6 80%, #fdfbcf 20%, #fffdf6 80%);
  height: 100vh;

  .button__back {
    position: fixed;
    left: 12.8rem;
    top: 6.4rem;
  }

  .logo {
    position: fixed;
    right: 12.8rem;
    top: 6.4rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 50rem;
    width: 100%;

    h1 {
      margin: 0 0 1.6rem 0;
      font-weight: normal;
      width: 100%;
    }

    p {
      color: var(--color-grey);
      width: 100%;
      margin: 0;
    }

    span {
      color: var(--color-grey);
      font-size: 1.2rem;
    }
  }
`
