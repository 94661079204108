import { ITitle } from '../../../components/Table'
import * as utils from '../../../utils'

export const tableTitle: ITitle[] = [
  { title: 'Nro. Pedido', attribute: 'order_number' },
  { title: 'Nro. NF', attribute: 'invoice_number' },
  { title: 'Nro. de série NF', attribute: 'invoice_serie_number' },
  { title: 'Valor Referência (indústria)', attribute: 'reference_value', formatter: utils.formatPrice },
  { title: 'Valor do frete', attribute: 'shipment_value', formatter: utils.formatPrice },
  { title: 'Valor da plataforma', attribute: 'comission_value', formatter: utils.formatPrice },
  { title: 'Valor fiscal', attribute: 'tax_value', formatter: utils.formatPrice },
  { title: 'Valor da NF', attribute: 'invoice_value', formatter: utils.formatPrice },
  {
    title: 'Valor depositado' + '\n' + '(Vr Referência + Vr fiscal)',
    attribute: 'deposited_value',
    formatter: utils.formatPrice
  }
]
