import React from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'

import { Container } from './styles'

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  customStyles?: React.CSSProperties
  label?: string
  name?: string

  register?: UseFormRegister<FieldValues>
  error?: FieldError
}

const TextArea: React.FC<IProps> = ({ customStyles, label, register, name, error, ...rest }) => (
  <Container customStyles={customStyles}>
    {label}

    {register && name ? <textarea {...register(name)} {...rest} /> : <textarea {...rest} />}

    <span>{!!error && error.message}</span>
  </Container>
)

export { TextArea }
