import React from "react";
import Select from "react-select";
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { Container } from "./styles";

interface IItems {
  value: string | number;
  label: string | number; // Renomeado para 'label' para se adequar ao react-select
}

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  customStyles?: React.CSSProperties;
  suggestions: IItems[];
  disabled?: boolean;
  onSelectChange?: (selectedOptions: any) => void;
  iconSearch?: boolean;
  label?: string;
  register?: UseFormRegister<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  error?: FieldError;
  control: Control;
}

const MultiSelect: React.FC<IProps> = ({
  customStyles,
  suggestions,
  onSelectChange,
  register,
  error,
  label,
  setValue,
  disabled,
  control,
  ...rest
}) => {
  const handleChange = (selectedOptions: any) => {
    if (rest.name && setValue) {
      // Extract values from selectedOptions
      const selectedValues = selectedOptions.map((option: any) => option.value);
      setValue(rest.name, selectedValues);
    }

    if (onSelectChange) {
      onSelectChange(selectedOptions);
    }
  };

  return (
    <Container customStyles={customStyles}>
      {label}

      <div className="box__input">
        <Controller
          name={rest.name || ""}
          control={control}
          render={({ field }) => {
            const selectedOptions = suggestions.filter(option => field.value?.includes(option.value))
            return (
              <Select
                {...field}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    "&:hover": {
                      border: "none",
                    },
                    boxShadow: "none",
                    margin: "auto 0 0 0",
                    background: "transparent",
                  }),
                }}
                options={suggestions}
                isMulti
                placeholder="Selecione"
                onChange={handleChange}
                isDisabled={disabled}
                value={selectedOptions}
              />
            );
          }}
        />
      </div>

      <span>{!!error && error.message}</span>
    </Container>
  );
};

export { MultiSelect };
