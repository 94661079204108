import styled from 'styled-components'

interface IContainerProps {
  disableHover?: boolean
  tableStyle?: string
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  font-size: 1.2rem;

  ${({ customStyles }) => customStyles}

  .table__header {
    display: grid;
    ${({ tableStyle }) => tableStyle}
    grid-gap: 1.6rem;

    font-weight: bold;
    padding: 1.6rem;
  }

  .table__main {
    border: 0.1rem solid var(--color-primaryTwo);
    border-radius: 0.8rem;
    background: white;
    padding: 0;
    margin: 0;

    li {
      display: grid;
      ${({ tableStyle }) => tableStyle}
      grid-gap: 1.6rem;

      border: solid 0.1rem transparent;
      font-weight: normal;
      padding: 1.6rem;
      margin: 0;

      span {
        display: flex;
        align-items: center;

        svg {
          margin: 0 0.4rem 0 0;
          height: 1.6rem;
          width: 1.6rem;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      ${({ disableHover }) =>
        !disableHover &&
        `&:hover {
          border: solid 0.1rem var(--color-blue);
          border-radius: 0.8rem;
          background: #2f80ed10;
        }`}
    }
  }

  .checkbox__container {
    border: 0.1rem solid #00000020;
    border-radius: 100%;
    position: relative;
    background: white;
    height: 3.2rem;
    width: 3.2rem;

    cursor: pointer;

    .checkbox__span {
      background: var(--color-blue);
      border-radius: 100%;
      height: 2.4rem;
      width: 2.4rem;

      position: absolute;
      left: 0.4rem;
      top: 0.4rem;
    }
  }

  .button__delete {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--color-pink);
    border-radius: 0.8rem;
    margin-left: auto;
    height: 3.2rem;
    width: 3.2rem;
    border: none;

    cursor: pointer;
  }
`

export const Paginator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 1.6rem;
  width: 100%;

  span {
    margin: 0 1.6rem;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--color-primaryTwo);
    border-radius: 0.8rem;
    cursor: pointer;
    height: 3.2rem;
    width: 3.2rem;
    border: none;
  }
`
