import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import * as Component from '../../../components'
import * as servicesFinancial from '../../../services/Financial'
import * as utils from '../../../utils'
import { Container } from './styles'

import { tableTitle } from './dataExamples'

const FinancialDepositDetails: React.FC = () => {
  const { history_id } = useParams<'history_id'>()

  const { isLoading, isFetched, data } = useQuery('financial/deposit-detail/' + history_id, fetchData)

  async function fetchData() {
    if (!history_id) return

    try {
      const { details } = await servicesFinancial.GetDepositDetail(history_id)
      return details
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar carregas os dados dessa tela', 'error')
    }
  }

  return (
    <>
      {isLoading && <Component.Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <div className="header__left">
              <Component.ButtonBack customStyles={{ marginRight: '1.6rem' }} />
              <h1>Valores Totais</h1>
            </div>
          </header>

          <main>
            <Component.Table titles={tableTitle} data={data} />
          </main>
        </Container>
      )}
    </>
  )
}

export { FinancialDepositDetails }
