import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { NavMenu } from '../components'
import * as pages from '../pages'
import { routesPrivate } from './routesLink'

const PrivateRoutes: React.FC = () => (
  <NavMenu>
    <Routes>
      <Route path={routesPrivate.home} element={<pages.Dashboard />} />
      <Route path={routesPrivate.financialDepositDetails + ':history_id'} element={<pages.FinancialDepositDetails />} />
      <Route path={routesPrivate.retailersOrderHistory + ':retailer_id'} element={<pages.RetailersOrderHistory />} />
      <Route path={routesPrivate.retailersOrderDetails + ':order_id'} element={<pages.RetailersOrderDetails />} />
      <Route path={routesPrivate.financialRegisteredAccounts} element={<pages.FinancialRegisteredAccounts />} />
      <Route path={routesPrivate.settingsCommercialOpinion} element={<pages.SettingsCommercialOpinion />} />
      <Route path={routesPrivate.financialChangeMainAccount} element={<pages.FinancialAlteredAccount />} />
      <Route path={routesPrivate.settingsRegistrationData} element={<pages.SettingsRegistrationData />} />
      <Route path={routesPrivate.settingsCommercialData} element={<pages.SettingsCommercialData />} />
      <Route path={routesPrivate.reviewsDetails + ':review_id'} element={<pages.ReviewsDetails />} />
      <Route path={routesPrivate.ordersDetails + ':order_id'} element={<pages.OrderDetails />} />
      <Route path={routesPrivate.settingsPassword} element={<pages.SettingsChangePassword />} />
      <Route path={routesPrivate.financialHistory} element={<pages.FinancialHistory />} />
      <Route path={routesPrivate.settingsContacts} element={<pages.SettingsContacts />} />
      <Route path={routesPrivate.financialCreate} element={<pages.FinancialCreate />} />
      <Route path={routesPrivate.settingsAddress} element={<pages.SettingsAddress />} />
      <Route path={routesPrivate.productsCreate} element={<pages.ProductsHandle />} />
      <Route path={routesPrivate.productsEdit} element={<pages.ProductsHandle />} />
      <Route path={routesPrivate.catalogSegments} element={<pages.Segments />} />
      <Route path={routesPrivate.catalogBrands} element={<pages.Brands />} />
      <Route path={routesPrivate.retailers} element={<pages.Retailers />} />
      <Route path={routesPrivate.financial} element={<pages.Financial />} />
      <Route path={routesPrivate.products} element={<pages.Products />} />
      <Route path={routesPrivate.settings} element={<pages.Settings />} />
      <Route path={routesPrivate.reviews} element={<pages.Reviews />} />
      <Route path={routesPrivate.orders} element={<pages.Orders />} />

      <Route path="*" element={<pages.PageNotFound />} />
    </Routes>
  </NavMenu>
)

export { PrivateRoutes }
