import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: normal;
      margin: 0;
    }
  }

  main {
    .orders__in__progress {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.6rem;

      margin-top: 1.6rem;

      main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.6rem;

        .order__column {
          display: flex;
          flex-direction: column;

          height: 12.8rem;

          &:last-child {
            align-items: flex-end;
          }

          strong {
            &:not(:last-child) {
              margin-bottom: auto;
            }
          }

          span {
            color: var(--color-grey);
            font-size: 1.2rem;
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;
        align-items: center;

        border-top: solid 0.1rem #00000020;
        padding-top: 1.6rem;
        margin-top: 1.6rem;
      }
    }

    .statistics {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 6.4rem;

      margin-top: 3.2rem;

      .statistics__left {
        h3 {
          &:not(:first-child) {
            margin: 6.4rem 0 1.6rem 0;
          }
        }

        span {
          color: var(--color-grey);
          font-size: 1rem;
        }

        strong {
          font-size: 1.4rem;
        }
      }

      .statistics__right {
        grid-column: 2 / 4;

        main {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 3.2rem;

          .statistics__product {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            height: 22rem;

            img {
              border-radius: 1.6rem;
              object-fit: cover;
              height: 16rem;
              width: 16rem;
            }

            strong {
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    .ranking__list {
      margin-top: 3.2rem;

      main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3.2rem;

        .ranking {
          p {
            color: var(--color-grey);
            font-size: 1rem;
            margin: 0;
          }

          strong {
            font-size: 1.2rem;
          }

          header {
            margin-bottom: 1.6rem;

            h4 {
              margin: 0 0 1.4rem 0;
              font-size: 1.4rem;
            }

            p {
              margin-top: auto;
            }
          }

          ul {
            li {
              display: flex;
              justify-content: space-between;

              margin: 0.8rem 0;

              strong {
                display: flex;

                .circle {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  border: solid 0.1rem var(--color-green);
                  color: var(--color-green);
                  margin: 0 0.8rem 0 0;
                  border-radius: 100%;
                  font-size: 0.8rem;
                  height: 1.2rem;
                  width: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
`
