import React from 'react'

import { UilAngleLeft, UilAngleRight, UilTrashAlt } from '@iconscout/react-unicons'

import { Container, Paginator } from './styles'

export interface ITitle {
  formatter?: Function
  attribute: string
  title: string
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  handleSetState?: React.Dispatch<React.SetStateAction<object>>
  getState?: any

  handleClick?: (id: string) => void
  handlePreviousPage?: () => void
  handleNextPage?: () => void
  hasPaginate?: boolean
  currentPage?: number
  totalPages?: number

  customStyles?: React.CSSProperties
  disableHover?: boolean
  hasSelector?: boolean
  hasDelete?: boolean

  titles: ITitle[]
  data?: object[]
}

const tableWithSelection = 'grid-template-columns: 3.2rem repeat(auto-fit, minmax(12.8rem, 1fr));'
const tableWithoutSelection = 'grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));'

const Table: React.FC<IProps> = props => {
  const { handleSetState, hasDelete, customStyles, disableHover, hasSelector, getState, titles, data } = props
  const { handlePreviousPage, handleNextPage, handleClick, hasPaginate, currentPage, totalPages } = props

  const onClick = (id: string) => {
    if (!handleClick || !id) return
    handleClick(id)
  }

  const titlesFormatted = titles.map(({ title, attribute }) => <span key={attribute}>{title}</span>)
  const tableStyle = hasSelector ? tableWithSelection : tableWithoutSelection

  const listFormatted = data?.map((object: any) => (
    <li key={object.id} onClick={() => onClick(object.id)}>
      {hasSelector && handleSetState && (
        <div className="checkbox__container" onClick={() => handleSetState(object)}>
          {getState?.id === object.id && <span className="checkbox__span" />}
        </div>
      )}

      {titles.map(({ attribute, formatter }) => (
        <span key={attribute}>{formatter ? formatter(object[attribute]) : object[attribute]}</span>
      ))}

      {!!hasDelete && handleSetState && (
        <button className="button__delete" onClick={() => handleSetState(object)}>
          <UilTrashAlt color="#FFF" />
        </button>
      )}
    </li>
  ))

  return (
    <Container customStyles={customStyles} disableHover={disableHover} tableStyle={tableStyle}>
      <div className="table__header">
        {hasSelector && <span />}
        {titlesFormatted}
        {hasDelete && <span />}
      </div>

      <ul className="table__main">{listFormatted}</ul>

      {hasPaginate && (
        <Paginator>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            <UilAngleLeft color="black" />
          </button>

          <span>
            {currentPage} de {totalPages}
          </span>

          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <UilAngleRight color="black" />
          </button>
        </Paginator>
      )}
    </Container>
  )
}

export { Table }
