import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'

import iconFood from '../../assets/images/iconFood.svg'
import * as Components from '../../components'
import * as servicesBrands from '../../services/Brands'
import * as utils from '../../utils'
import * as Styles from './styles'

import { IBrands } from '../../types'
import { schemeCreateBrand, schemeEditBrand } from './schemeBrand'

const Brands: React.FC = () => {
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined)
  const [openModalCropper, setOpenModalCropper] = useState<boolean>(false)
  const [brand, setBrand] = useState<IBrands | null>(null)

  const [params, setParams] = useState({ pageOffset: 8, page: 1 })
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalBrand, setOpenModalBrand] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const scheme = isEdit ? schemeEditBrand : schemeCreateBrand

  const { formState, handleSubmit, setValue, register } = useForm({ resolver: yupResolver(scheme) })
  const { isLoading, isFetched, data, refetch } = useQuery('brands', fetchData)
  const { errors } = formState

  async function fetchData() {
    try {
      return await servicesBrands.BrandsIndustryIndex(params)
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar carregar marcas', 'error')
    }
  }

  async function handleCreateOrUpdateBrand({ name, description }: any): Promise<void> {
    setIsUpdating(true)

    try {
      const payload = { description, name }

      if (!!previewImage && !previewImage.includes('https://')) {
        const byteCharacters = utils.convertBase64ToFile(previewImage)
        const fileCompressed = await utils.handleImageCompress(byteCharacters, { maxWidthOrHeight: 400 })

        const [image] = await utils.uploadImages(fileCompressed || brand?.image)
        Object.assign(payload, { image })
      }

      if (isEdit && brand?.id) await servicesBrands.BrandsIndustryUpdate({ id: brand.id, payload })
      if (!isEdit) await servicesBrands.BrandsIndustryCreate(payload)

      utils.toastify(`Marca ${isEdit ? 'editada' : 'criada'} com sucesso`, 'success')
      await refetch()

      setOpenModalBrand(false)
    } catch (error) {
      utils.toastify(`Erro ao tentar ${isEdit ? 'editar' : 'criar'} a marca`, 'error')
    }

    setIsUpdating(false)
  }

  async function handleDeleteBrand(): Promise<void> {
    try {
      if (brand?.id) await servicesBrands.BrandsIndustryDelete(brand.id)
      await refetch()

      utils.toastify('Produto Excluído com sucesso!', 'success')
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar excluir este produto!', 'error')
    }

    setOpenModalDelete(false)
    setBrand(null)
  }

  function handleModalDeleteBrand(brand: any): void {
    setOpenModalDelete(true)
    setBrand(brand)
  }

  function handleModalCreateOrEditBrand(brand: any): void {
    if (brand.image) setPreviewImage(brand.image)

    for (const prop in brand) {
      setValue(prop, brand[prop])
    }

    setOpenModalBrand(true)
    setIsEdit(true)
    setBrand(brand)
  }

  function handleOpenModalBrand(): void {
    setValue('description', '')
    setValue('name', '')

    setPreviewImage(undefined)
    setIsEdit(false)

    setOpenModalBrand(true)
  }

  function handleChangeState(event: any): void {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params.page])

  return (
    <>
      <Components.Modal
        title={isEdit ? 'Editar marca' : 'Nova marca'}
        setIsOpen={setOpenModalBrand}
        isOpen={openModalBrand}
      >
        <Styles.BoxImageContainer>
          <div className="box__image">
            <img src={previewImage} alt="" />

            <button className="link" onClick={() => setOpenModalCropper(true)}>
              Alterar imagem
            </button>
          </div>

          <form onSubmit={handleSubmit(handleCreateOrUpdateBrand)}>
            <Components.Input
              placeholder="Insira aqui o nome da marca"
              label="Nome"
              name="name"
              error={errors.name}
              register={register}
            />

            <Components.Input
              placeholder={`Insira aqui uma descrição${isEdit ? ' (opcional)' : ''}`}
              name="description"
              label="Descrição"
              error={errors.description}
              register={register}
            />

            <Components.Button
              customStyles={{ maxWidth: '25.6rem', width: '100%', marginTop: '3.2rem', marginLeft: 'auto' }}
              disabled={isUpdating}
              type="submit"
            >
              {isEdit ? 'Editar' : 'Adicionar'}
            </Components.Button>
          </form>
        </Styles.BoxImageContainer>
      </Components.Modal>

      <Components.ModalCropImage
        title="Inserir imagem do produto"
        setPreviewImage={setPreviewImage}
        setIsOpen={setOpenModalCropper}
        previewImage={previewImage}
        isOpen={openModalCropper}
      />

      <Components.Modal setIsOpen={setOpenModalDelete} isOpen={openModalDelete} title="">
        <Styles.BoxModalDelete>
          <h1>Tem certeza que deseja excluir o produto {brand?.name}?</h1>

          <div className="box__options">
            <Components.Button onClick={handleDeleteBrand}>Sim</Components.Button>
            <Components.Button onClick={() => setOpenModalDelete(false)}>Não</Components.Button>
          </div>
        </Styles.BoxModalDelete>
      </Components.Modal>

      <Styles.Container>
        <h1>Marcas</h1>

        <div className="header__options">
          <Components.Input placeholder="Pesquisar por marcas" onChange={handleChangeState} name="name" iconSearch />

          <Components.Button onClick={() => refetch()} customStyles={{ marginTop: '2.4rem' }}>
            Pesquisar
          </Components.Button>

          <Components.Button onClick={handleOpenModalBrand} customStyles={{ marginTop: '2.4rem' }}>
            Adicionar nova marca
          </Components.Button>
        </div>

        {isLoading && <Components.Loading style={{ margin: '0 auto' }} />}

        {isFetched && (
          <>
            <div className="container__brands">
              {data?.results?.map(brand => (
                <Components.CardBox key={brand.id} className="box__brand">
                  <img src={brand?.image || iconFood} alt="" />

                  <h2>{brand?.name}</h2>

                  <div className="divider" />

                  <div>
                    <button className="link" onClick={() => handleModalCreateOrEditBrand(brand)}>
                      Editar
                    </button>

                    <button onClick={() => handleModalDeleteBrand(brand)} className="link">
                      Excluir
                    </button>
                  </div>
                </Components.CardBox>
              ))}
            </div>

            <Components.Paginate
              customStyles={{ position: 'fixed', bottom: '1.6rem' }}
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              currentPage={params.page}
            />
          </>
        )}
      </Styles.Container>
    </>
  )
}

export { Brands }
