import ApiClient from '../ApiClient'
import { IGetReviewsAllResponse, IGetReviewResponse, IGetReviewsAllParams } from './interfaces'

const route = '/api/order_reviews/industry/'

const GetReview = (review_id: string): Promise<IGetReviewResponse> => ApiClient.api.get(route + 'details/' + review_id)

const GetReviewsAll = (params: IGetReviewsAllParams): Promise<IGetReviewsAllResponse> =>
  ApiClient.api.get(route, { params })

export { GetReviewsAll, GetReview }
