import { IAddress } from '../../types'
import ApiClient from '../ApiClient'
import { IAddressPayload, IAddressResponse } from './interfaces'

const route = '/api/industries/address/'

const GetIndustryAddress = (industry_id: string): Promise<IAddressResponse> => ApiClient.api.get(route + industry_id)

const PatchIndustryAddress = ({ industry_id, payload }: IAddressPayload): Promise<IAddress> =>
  ApiClient.api.patch(route + industry_id, payload)

export { PatchIndustryAddress, GetIndustryAddress }
