import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'

import { UilSignout, UilUser } from '@iconscout/react-unicons'

import logoVa from '../../assets/images/logoVA.svg'
import { useAuth } from '../../hooks/useAuth'
import { Container } from './styles'
import { navMenuLinks, subMenuAnimate } from './utilsMenuLinks'

const NavMenu: React.FC = ({ children }) => {
  const [indexSubMenu, setIndexSubMenu] = useState<number | null>(null)

  const { handleSignOut, user } = useAuth()

  const closeSubMenu = () => setIndexSubMenu(null)

  return (
    <Container>
      <nav className="navMenu__container">
        <div className="box__left">
          <div className="logo">
            <img src={logoVa} alt="" />
          </div>

          <ul>
            {navMenuLinks.map((link, index) => (
              <motion.li onHoverStart={() => setIndexSubMenu(index)} onHoverEnd={closeSubMenu} key={link.title}>
                {link.path ? <Link to={link.path}>{link.title}</Link> : <span>{link.title}</span>}

                {link.subMenus && (
                  <motion.div
                    animate={indexSubMenu === index ? 'enter' : 'exit'}
                    variants={subMenuAnimate}
                    className="submenu"
                    initial="exit"
                  >
                    <ul>
                      {link.subMenus.map(subMenu => (
                        <li key={subMenu.path}>
                          <Link to={subMenu.path}>{subMenu.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </motion.li>
            ))}
          </ul>
        </div>

        <div className="box__right">
          <div className="divider" />

          <div className="box__card">
            <div className="icon">
              <UilUser size="2.4rem" color="var(--color-primaryOne)" />
            </div>

            <p>
              Olá,
              <br />
              <strong>{user?.name}</strong>
            </p>
          </div>

          <div className="divider" />

          <div className="box__card">
            <div className="icon">
              <UilSignout size="2.4rem" color="var(--color-primaryOne)" />
            </div>

            <button onClick={handleSignOut}>Sair</button>
          </div>
        </div>
      </nav>

      <div className="container">{children}</div>
    </Container>
  )
}

export { NavMenu }
