import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import { UilDollarSignAlt } from '@iconscout/react-unicons'

import * as Component from '../../../components'
import { routesPrivate } from '../../../routes/routesLink'
import * as servicesFinancial from '../../../services/Financial'
import * as utils from '../../../utils'
import { Container } from './styles'

import { financialSuggestions, tableTitle } from './dataExamples'

const FinancialHistory: React.FC = () => {
  const { isLoading, isFetched, refetch, data } = useQuery('financial/history', fetchData)
  const navigate = useNavigate()

  const [params, setParams] = useState({ pageOffset: 6, page: 1 })

  async function fetchData() {
    try {
      const { total_pages, results } = await servicesFinancial.GetFinancialHistory(params)
      const forecast_values = await servicesFinancial.GetForecastValues()

      const transactions = results.map(item => ({
        status_formatted: statusFormatter(item),
        receipt_formatted: receiptFormatter(item),
        account_formatted: accountFormatter(item),
        agency_formatted: agencyFormatter(item),
        ...item
      }))

      return { forecast_values, transactions, total_pages }
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar carregas os dados dessa tela', 'error')
    }
  }

  function agencyFormatter(item: any): string {
    return `${item.agency}${item.agency_digit ? `-${item.agency_digit}` : ''}`
  }

  function accountFormatter(item: any): string {
    return `${item.account_number}${item.account_digit ? `-${item.account_digit}` : ''}`
  }

  function receiptFormatter(item: any): React.ReactElement | string {
    if (!item.receipt) return 'Não disponível'
    return (
      <div>
        <a
          className="link"
          style={{ fontSize: '1.2rem' }}
          href={item.receipt}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visualizar
        </a>
      </div>
    )
  }

  function statusFormatter(item: any): string {
    switch (item.status) {
      case 'pending':
        return 'Em análise'
      case 'transferred':
        return 'Transferido'
      case 'expected_deposit':
        return 'A receber'
      default:
        return ''
    }
  }

  function handleChangeParams(event: any): void {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))
  const handleRedirect = (id: string) => {
    const transaction = data?.transactions.find(item => item.id === id)
    if (transaction && transaction.status === 'transferred') {
      navigate(routesPrivate.financialDepositDetails + id)
    } else {
      utils.toastify('Não é possível visualizar os detalhes dessa transação', 'error')
    }
  }

  useEffect(() => {
    refetch()
  }, [params])

  return (
    <>
      {isLoading && <Component.Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <div className="header__left">
              <Component.ButtonBack customStyles={{ marginRight: '1.6rem' }} />
              <h1>Histórico financeiro</h1>
            </div>

            <Link to={routesPrivate.financialRegisteredAccounts}>
              <Component.Button>Contas cadastradas</Component.Button>
            </Link>
          </header>

          <main>
            <section className="box__cards">
              <Component.CardBox className="card">
                <div className="card__values">
                  <span>Extrato total</span>
                  <p>{utils.formatPrice(data?.forecast_values.total)}</p>
                </div>

                <UilDollarSignAlt />
              </Component.CardBox>

              <Component.CardBox className="card">
                <div className="card__values">
                  <span>Disponível</span>
                  <p>{utils.formatPrice(data?.forecast_values.available)}</p>
                </div>

                <UilDollarSignAlt />
              </Component.CardBox>

              <Component.CardBox className="card">
                <div className="card__values">
                  <span>A receber</span>
                  <p>{utils.formatPrice(data?.forecast_values.to_receive)}</p>
                </div>

                <UilDollarSignAlt />
              </Component.CardBox>

              <Component.CardBox className="card">
                <div className="card__values">
                  <span>Bloqueado</span>
                  <p>{utils.formatPrice(data?.forecast_values.blocked_value)}</p>
                </div>

                <UilDollarSignAlt />
              </Component.CardBox>
            </section>

            <section className="box__search">
              <Component.Input
                placeholder="Pesquisar pelo Banco, Agência, Conta e CPF/CNPJ"
                onChange={handleChangeParams}
                name="keyword"
                iconSearch
              />

              <Component.Select
                suggestions={financialSuggestions}
                onChange={handleChangeParams}
                name="status"
                placeholder="Status"
              />
            </section>

            <Component.Table
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              handleClick={handleRedirect}
              currentPage={params.page}
              data={data?.transactions}
              titles={tableTitle}
              hasPaginate
            />
          </main>
        </Container>
      )}
    </>
  )
}

export { FinancialHistory }
