import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;

    .header__left {
      display: flex;
      align-items: flex-end;

      h1 {
        margin: 0 3.2rem 0 0;
        font-weight: normal;
      }

      span {
        color: var(--color-grey);
      }
    }
  }

  main {
    display: grid;
    grid-template-columns: 1fr 25.6rem;
    grid-gap: 6.4rem;

    margin-top: 6.4rem;

    .general__settings {
      border-right: solid 0.1rem #00000020;

      section {
        display: grid;
        grid-template-columns: auto 1fr 2fr;
        grid-gap: 3.2rem;

        margin-right: 6.4rem;

        &:not(:last-child) {
          border-bottom: solid 0.1rem #00000020;
          padding-bottom: 12.8rem;
          margin-bottom: 12.8rem;
        }

        .map__coordinates,
        .industry__logo {
          border-radius: 1.6rem;
          height: 20rem;
          width: 20rem;
        }

        .industry__logo {
          background: #00000010;
          object-fit: cover;
        }

        .map__container {
          border: 1px solid #b3dae2;
          border-radius: 20px;
          background: #e6f7fb;
        }

        .industry__data {
          display: flex;
          flex-direction: column;

          h3 {
            margin: 1.6rem 0 0.8rem 0;
          }

          span,
          a {
            color: var(--color-black);
            margin-top: 1.2rem;
            font-weight: 300;
          }
        }

        .industry__options {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;

          button {
            border-left: solid 0.1rem #00000020;
            text-decoration: none;
            padding-left: 1.6rem;
            font-size: 1.6rem;
            font-weight: 300;
          }
        }
      }
    }

    aside {
      a {
        display: flex;
        align-items: center;

        border-radius: 1.2rem;
        background: #00000020;
        cursor: pointer;
        border: none;
        width: 100%;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 2.4rem;
        }

        svg {
          background: var(--color-primaryOne);
          color: var(--color-primaryTwo);
          border-radius: 1.2rem;
          margin-right: 1.6rem;
          padding: 1.6rem;
          height: 1.8rem;
          width: 1.8rem;
        }
      }
    }
  }
`
