import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__left {
      display: flex;
      align-items: center;
    }
  }
`
