import React, { useEffect, useRef, useState } from 'react'
import { Cropper } from 'react-cropper'

import { UilEditAlt } from '@iconscout/react-unicons'

import * as Component from '../../../components'
import { useAuth } from '../../../hooks/useAuth'
import { GetIndustry, PatchIndustry } from '../../../services/Industry'
import { IIndustryResponse } from '../../../services/Industry/interfaces'
import * as utils from '../../../utils'
import { Container, ModalContainer } from './styles'

const SettingsRegistrationData: React.FC = () => {
  const cropperRef = useRef<HTMLImageElement>(null)
  const { user } = useAuth()

  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined)
  const [fileImage, setFileImage] = useState<File | undefined>(undefined)
  const [industry, setIndustry] = useState<IIndustryResponse>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState(false)

  async function fetchData() {
    try {
      if (user) {
        const response = await GetIndustry(user.id)

        setPreviewImage(response.logo)
        setIndustry(response)
      }
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar carregar os dados cadastrais', 'error')
    }
  }

  async function handleUpdateIndustry(): Promise<void> {
    setIsUpdating(true)

    try {
      if (industry) {
        const industry_id = industry?.id

        const [logo] = await utils.uploadImages(fileImage)
        await PatchIndustry({ industry_id, payload: { logo } })

        utils.toastify('Dados cadastrais atualizados com sucesso', 'success')
      }
    } catch (error) {
      utils.toastify('Ocorreu um erro ao tentar atualizar os dados cadastrais', 'error')
    }

    setIsUpdating(false)
  }

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const maximumSize = 4 * 1024 * 1024

    const selectedImages = Array.from(event.target.files)

    const selectedImagesPreview = selectedImages?.map(image => {
      if (image.type !== 'image/jpg' && image.type !== 'image/jpeg') {
        utils.toastify('A imagem deve ser no formato .jpg ou .jpeg', 'error')
        return ''
      }

      if (image.size >= maximumSize) {
        utils.toastify('Insira uma imagem menor que 4MB', 'error')
        return ''
      }

      return URL.createObjectURL(image)
    })

    setPreviewImage(selectedImagesPreview[0])
    setFileImage(selectedImages[0])
  }

  const handleGetCropData = async () => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper

    if (typeof cropper !== 'undefined') {
      const fileBase64 = cropper.getCroppedCanvas().toDataURL()
      const byteCharacters = utils.convertBase64ToFile(fileBase64)
      const fileCompressed = await utils.handleImageCompress(byteCharacters, { maxWidthOrHeight: 400, maxSizeMB: 1 })

      setFileImage(fileCompressed)
      setPreviewImage(fileBase64)
      setOpenModal(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Component.Modal setIsOpen={setOpenModal} isOpen={openModal} title="Alterar Logo">
        <ModalContainer>
          <div className="box__image">
            <Cropper
              style={{ height: '40rem', width: '40rem' }}
              src={previewImage}
              ref={cropperRef}
              aspectRatio={1}
              guides={false}
            />

            <input onChange={handleChangeImage} accept="image/jpg, image/jpeg" id="image[]" type="file" />

            <div className="box__options">
              <label htmlFor="image[]" className="link">
                inserir nova imagem
              </label>

              <button type="button" className="link" onClick={handleGetCropData}>
                Salvar alteração
              </button>
            </div>
          </div>
        </ModalContainer>
      </Component.Modal>

      <Container>
        <header>
          <Component.ButtonBack />
          <h1>Dados cadastrais</h1>
        </header>

        <form>
          <div className="box__logo">
            <img src={previewImage} alt={`Logo da industria ${industry?.name}`} />

            <button className="button__change__logo" type="button" onClick={() => setOpenModal(true)}>
              <UilEditAlt color="grey" size="1.2rem" />
            </button>
          </div>

          <Component.InputMask
            customStyles={{ marginBottom: '1.6rem' }}
            placeholder="Insira seu CNPJ"
            mask="99.999.999/9999-99"
            label="CNPJ"
            value={industry?.cnpj}
            disabled
          />

          <Component.Input
            placeholder="Núm. da sua inscrição estadual"
            customStyles={{ marginBottom: '1.6rem' }}
            label="INSCRIÇÃO ESTADUAL"
            defaultValue={industry?.ie}
            disabled
          />

          <Component.Input
            placeholder="Núm. da sua inscrição municipal"
            customStyles={{ marginBottom: '1.6rem' }}
            label="INSCRIÇÃO MUNICIPAL"
            defaultValue={industry?.im}
            disabled
          />

          <Component.Input
            customStyles={{ marginBottom: '1.6rem' }}
            label="RAZÃO SOCIAL"
            placeholder="Insira sua razão social"
            defaultValue={industry?.company_name}
            disabled
          />

          <Component.Button
            customStyles={{ margin: '0 auto' }}
            onClick={handleUpdateIndustry}
            disabled={isUpdating}
            type="button"
          >
            Salvar alterações
          </Component.Button>
        </form>
      </Container>
    </>
  )
}

export { SettingsRegistrationData }
