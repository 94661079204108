import React, { useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { UilCommentQuestion, UilLocationPoint, UilBookReader, UilUser } from '@iconscout/react-unicons'

import notImage from '../../assets/images/notImage.png'
import { Button, Skeleton } from '../../components'
import { useAuth } from '../../hooks/useAuth'
import { routesPrivate } from '../../routes/routesLink'
import { GetIndustryAddress } from '../../services/Address'
import { GetIndustry } from '../../services/Industry'
import { formatMask, toastify } from '../../utils'
import { Container } from './styles'

const Settings: React.FC = () => {
  const { isFetched, data } = useQuery('industry-settings', fetchData)
  const { handleSignOut, user } = useAuth()

  const [coordinates, setCoordinates] = useState<any>(undefined)

  async function fetchData() {
    if (!user) return

    try {
      const addressResponse = await GetIndustryAddress(user.id)
      const industryResponse = await GetIndustry(user.id)

      const { latitude, longitude } = addressResponse

      if (latitude && longitude) setCoordinates([latitude, longitude])

      return { address: addressResponse, industry: industryResponse }
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregar os dados cadastrais', 'error')
    }
  }

  return (
    <Container>
      <header>
        <div className="header__left">
          <h1>Configurações</h1>
          <span>Meu perfil</span>
        </div>

        <Button onClick={handleSignOut}>Sair</Button>
      </header>

      <main>
        <div className="general__settings">
          <section>
            <img className="industry__logo" src={data?.industry?.logo || notImage} alt="" />

            <div className="industry__data">
              {isFetched ? (
                <>
                  <h3>{data?.industry?.company_name}</h3>

                  <span>{formatMask(data?.industry?.cnpj, '##.###.###/####-##')}</span>

                  {data?.industry?.phone_number && (
                    <a href={`tel:${data?.industry?.phone_number}`}>
                      Contato - {formatMask(data?.industry?.phone_number, '(##) # ####-####')}
                    </a>
                  )}

                  <a href={`mailto:${data?.industry?.email}`}>{data?.industry?.email}</a>
                </>
              ) : (
                <>
                  <Skeleton shape="title" />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              )}
            </div>

            <div className="industry__options">
              <Link to={routesPrivate.settingsRegistrationData}>
                <button className="link">Visualizar</button>
              </Link>
            </div>
          </section>

          <section>
            <div className="map__container">
              {coordinates ? (
                <MapContainer
                  className="map__coordinates"
                  scrollWheelZoom={false}
                  doubleClickZoom={false}
                  center={coordinates}
                  zoomControl={false}
                  touchZoom={false}
                  dragging={false}
                  zoom={16}
                >
                  <TileLayer
                    url={`https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`}
                  />
                </MapContainer>
              ) : (
                <div className="map__coordinates" />
              )}
            </div>

            <div className="industry__data">
              <h3>Endereço principal</h3>

              {isFetched ? (
                <>
                  <span>
                    {data?.address?.address}, {data?.address?.address_number} {data?.address?.complement}
                  </span>

                  <span>
                    Bairro {data?.address?.neighborhood} - CEP {formatMask(data?.address?.zip_code, '##.###-###')}
                  </span>

                  <span>{data?.address?.state_name} - Brasil</span>
                </>
              ) : (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              )}
            </div>

            <div className="industry__options">
              <Link to={routesPrivate.settingsAddress}>
                <button className="link">Visualizar</button>
              </Link>
            </div>
          </section>
        </div>

        <aside>
          <Link to={routesPrivate.settingsRegistrationData}>
            <UilUser />
            Dados cadastrais
          </Link>

          <Link to={routesPrivate.settingsAddress}>
            <UilLocationPoint />
            Endereço principal
          </Link>

          <Link to={routesPrivate.settingsContacts}>
            <UilBookReader />
            Contatos
          </Link>

          <Link to={routesPrivate.home}>
            <UilCommentQuestion />
            Ajuda
          </Link>
        </aside>
      </main>
    </Container>
  )
}

export { Settings }
