import styled from 'styled-components'

interface IContainerProps {
  customStyles?: any
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: var(--color-primaryOne);
  border-radius: 1.6rem;
  font-size: 1.4rem;
  font-weight: 300;
  width: 10rem;
  color: white;

  ${({ customStyles }) => customStyles}

  button {
    margin: 0.4rem 0.4rem 0 0.4rem;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;

    svg {
      height: 1.6rem;
      width: 1.6rem;
      padding: 0;
      margin: 0;
    }
  }

  ${({ customStyles }) => customStyles}
`
