import React from 'react'
import { Route, Routes } from 'react-router-dom'

import * as pages from '../pages'
import { routesPublic } from './routesLink'

const PublicRoutes: React.FC = () => (
  <Routes>
    <Route path={routesPublic.login} element={<pages.Login />} />
    <Route path="*" element={<pages.PageNotFound />} />
  </Routes>
)

export { PublicRoutes }
