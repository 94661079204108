import React, { useEffect, useState } from 'react'

import { UilMinus, UilPlus } from '@iconscout/react-unicons'

import { Container } from './styles'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  handleChangeValue: (index: number, value: number) => void
  customStyles?: React.CSSProperties
  defaultValue?: number
  disabled?: boolean
  index: number
  mask?: string
}

const ButtonChangeValue: React.FC<IProps> = ({
  handleChangeValue,
  defaultValue,
  customStyles,
  disabled,
  index,
  mask
}) => {
  const [value, setValue] = useState<number>(1)

  const handleSubtraction = () => (value <= 1 || disabled ? null : setValue(value - 1))
  const handleAddition = () => (disabled ? null : setValue(value + 1))

  useEffect(() => handleChangeValue(index, value), [value])

  useEffect(() => setValue(defaultValue || 1), [])

  return (
    <Container customStyles={customStyles}>
      <button onClick={handleSubtraction}>
        <UilMinus color="white" />
      </button>
      {value} {mask}
      <button onClick={handleAddition}>
        <UilPlus color="white" />
      </button>
    </Container>
  )
}

export { ButtonChangeValue }
