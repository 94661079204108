import * as yup from 'yup'

export const schemeSettingsChangePassword = yup.object().shape({
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'As senhas não conferem'),
  old_password: yup.string().required('Campo obrigatório'),
  password: yup
    .string()
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número')
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .required('Campo obrigatório')
})
