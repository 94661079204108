import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { Button, ButtonBack, Loading, Modal, Table } from '../../../components'
import { BankDelete, BanksList } from '../../../services/Banks'
import { toastify } from '../../../utils'
import { ConfirmaModal, Container } from './styles'
import { exampleTitleMainAccount } from './utils'

const FinancialRegisteredAccounts: React.FC = () => {
  const { refetch, isLoading, isFetched, data } = useQuery('financial/registered-accounts', fetchData)

  const [params, setParams] = useState({ pageOffset: 6, page: 1 })
  const [accountSelect, setAccountSelect] = useState<any>()
  const [openModal, setOpenModal] = useState(false)

  async function fetchData() {
    try {
      const { results: bankMain } = await BanksList({ pageOffset: 1 })
      const { results: banks, total_pages } = await BanksList(params)

      return { total_pages, bankMain, banks }
    } catch (error) {
      toastify('Ocorreu um erro ao tentar carregas os dados dessa tela', 'error')
    }
  }

  async function handleDelete() {
    try {
      await BankDelete(accountSelect.id)
      toastify('Conta bancária excluída com sucesso', 'success')
      await refetch()
    } catch (error) {
      toastify('Ocorreu um erro ao tentar excluir a conta bancária', 'error')
    }

    setAccountSelect(null)
    setOpenModal(false)
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    if (accountSelect?.id) setOpenModal(true)
  }, [accountSelect])

  useEffect(() => {
    refetch()
  }, [params])

  return (
    <>
      <Modal title="Atenção!" isOpen={openModal} setIsOpen={setOpenModal}>
        <ConfirmaModal>
          <p>Tem certeza que deseja excluir essa conta?</p>

          <div className="box__options">
            <Button
              onClick={() => {
                setAccountSelect(null)
                setOpenModal(false)
              }}
              customStyles={{ marginRight: '3.2rem' }}
              clearTemplate
            >
              Não
            </Button>

            <Button onClick={handleDelete}>Sim</Button>
          </div>
        </ConfirmaModal>
      </Modal>

      {isLoading && <Loading style={{ margin: '0 auto' }} />}

      {isFetched && (
        <Container>
          <header>
            <ButtonBack customStyles={{ marginRight: '1.6rem' }} />
            <h1>Contas cadastradas</h1>

            {/* <Link to={routesPrivate.financialCreate} style={{ marginLeft: 'auto' }}>
              <Button>Adicionar nova conta</Button>
            </Link> */}
          </header>

          <main>
            <section className="main__account">
              <header>
                <h2>Conta principal</h2>

                {/* <Link className="link" to={routesPrivate.financialChangeMainAccount}>
                  alterar conta principal
                </Link> */}
              </header>

              <Table titles={exampleTitleMainAccount} data={data?.bankMain} disableHover />
            </section>

            <section className="others__accounts">
              <header>
                <h2>Outras contas cadastradas</h2>
              </header>

              <Table
                handlePreviousPage={handlePreviousPage}
                totalPages={data?.total_pages || 1}
                handleSetState={setAccountSelect}
                titles={exampleTitleMainAccount}
                handleNextPage={handleNextPage}
                currentPage={params.page}
                data={data?.banks}
                hasPaginate
                // hasDelete
              />
            </section>
          </main>
        </Container>
      )}
    </>
  )
}

export { FinancialRegisteredAccounts }
