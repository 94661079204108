import imageCompression from 'browser-image-compression'

interface ICompressOptions {
  fileType?: 'image/jpeg' | 'image/png'
  alwaysKeepResolution?: boolean
  maxWidthOrHeight?: number
  initialQuality?: number
  maxSizeMB?: number
}

const defaultOptions = {
  alwaysKeepResolution: true,
  maxWidthOrHeight: 800,
  fileType: 'image/jpeg',
  initialQuality: 0.6,
  maxSizeMB: 1
}

const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development'

const handleImageCompress = async (imageFile: File | undefined, options?: ICompressOptions) => {
  if (!imageFile) return undefined

  Object.assign(defaultOptions, { ...options })

  try {
    const compressedFile = await imageCompression(imageFile, defaultOptions)

    if (isDevelopment) {
      console.log('Original File instanceof Blob', imageFile instanceof Blob)
      console.log(`Original File size ${imageFile.size / 1024 / 1024} MB`)

      console.log('Compressed File instanceof Blob', compressedFile instanceof Blob)
      console.log(`Compressed File size ${compressedFile.size / 1024 / 1024} MB`)
    }

    return compressedFile
  } catch (error) {
    console.log(error)
  }
}

export { handleImageCompress }
