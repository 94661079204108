import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import iconFood from '../../../assets/images/iconFood.svg'
import { Button, ButtonBack, ButtonChangeValue, CardBox, Input, Loading, Modal } from '../../../components'
import { GetOrders } from '../../../services/Orders'
import { formatDate, formatMask, formatPrice, toastify } from '../../../utils'
import { Container, ModalContainer } from './styles'

import { ORDER_STATUS, PAYMENT_STATUS } from '../../../constants'

const OrderDetails: React.FC = () => {
  const { order_id } = useParams<'order_id'>()
  const { isLoading, isFetched, data } = useQuery(`orders/${order_id}`, fetchData)

  const [openModal, setOpenModal] = useState<boolean>(false)

  async function fetchData() {
    try {
      if (order_id) return await GetOrders(order_id)
    } catch (error) {
      toastify('Erro ao carregar informações do pedido', 'error')
    }
  }

  function handleChangeItems(index: number, value: number): void {
    console.log('handleChangeItems: ', index, value)

    // const itens = data?.itens?.map((item, indexItem) => {
    //   if (index === indexItem) {
    //     item.amount = value
    //     return item
    //   } else {
    //     return item
    //   }
    // })

    // Object.assign(order, { itens })
    // setOrder(order)
  }

  return (
    <>
      {isLoading && <Loading style={{ margin: '0 auto' }} />}

      <Modal isOpen={openModal} setIsOpen={setOpenModal} title="Editar produtos">
        <ModalContainer>
          <main>
            {data?.itens?.map((item, index) => (
              <CardBox key={item.id} customStyles={{ display: 'flex', alignItems: 'center', padding: '0.8rem' }}>
                <img src={item.image || iconFood} alt="" />

                <div className="product__details">
                  <p>{item.product}</p>
                  <p>Qnt. {item.amount}</p>

                  <ButtonChangeValue
                    customStyles={{ marginTop: '1.6rem' }}
                    handleChangeValue={handleChangeItems}
                    defaultValue={item.amount}
                    index={index}
                    mask="cx"
                  />
                </div>
              </CardBox>
            ))}
          </main>

          <footer>
            <Button>Adicionar novo produto</Button>
            <Button clearTemplate>Salvar</Button>
          </footer>
        </ModalContainer>
      </Modal>

      {isFetched && (
        <Container>
          <header>
            <div className="header__left">
              <ButtonBack customStyles={{ marginRight: '1.6rem' }} />
              <h1>Pedido #{data?.order_number}</h1>
            </div>
          </header>

          <main>
            <CardBox className="box__order" label="Pedido">
              <Input defaultValue={ORDER_STATUS[data?.status || '']} label="STATUS" disabled />
              <Input label="NÚMERO DO PEDIDO" defaultValue={data?.order_number} disabled />
            </CardBox>

            <CardBox className="box__details__client" label="Dados do cliente">
              <div>
                <strong>Nome do varejista:</strong>
                <p>{data?.retailer.company_name}</p>
              </div>

              <div>
                <strong>Telefone:</strong>
                <p>{formatMask(data?.retailer.phone_number, '(##) # ####-####')}</p>
              </div>

              <div>
                <strong>E-mail:</strong>
                <p>{data?.retailer.email}</p>
              </div>

              <div>
                <strong>CNPJ:</strong>
                <p>{formatMask(data?.retailer.cnpj, '##.###.###/####-##')}</p>
              </div>

              <div>
                <strong>Núm. de pedidos:</strong>
                <p>{data?.retailer.total_orders || 0} pedidos</p>
              </div>
            </CardBox>

            <CardBox label="Registro de envio" className="box__shipping__record">
              <div>
                <strong>Código de rastreio</strong>
                <p>{data?.shipment?.track_code || 'não se aplica'}</p>
              </div>

              <div>
                <strong>Data da postagem</strong>
                <p>Pedido postado em {formatDate(data?.shipment?.dispatched_at)}</p>
              </div>

              <div>
                <strong>Data estimada para entrega</strong>
                <p>Entrega estimada para {formatDate(data?.shipment?.estimated_date)}</p>
              </div>
            </CardBox>

            <CardBox label="Pagamento" className="box__invoice">
              <div>
                <strong>Forma de pagamento:</strong>
                <p>{data?.orderPayment?.payment?.payment_term}</p>
              </div>

              <div>
                <strong>Status:</strong>
                <p>{PAYMENT_STATUS[data?.orderPayment?.status || '']}</p>
              </div>
            </CardBox>

            {/* <CardBox
            label="Nota fiscal"
            customStyles={{
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: '1.6rem',
              display: 'grid'
            }}
          >
            <Input
              customStyles={{ gridColumn: '1 / 3' }}
              label="CHAVE DA NOTA FISCAL"
              placeholder="Insira aqui a chave da NF"
              value="XXX"
            />

            <Input label="NÚMERO DA NOTA FISCAL" placeholder="Insira aqui o número da NF" value="XXX" />

            <Input label="SÉRIE DA NOTA FISCAL" placeholder="Insira aqui a série da NF" value="XXX" />

            <Input label="DATA DO FATURAMENTO" placeholder="Insira aqui a data do faturamento" value="XXX" />

            <Input
              customStyles={{ gridColumn: '2 / 5' }}
              placeholder="Insira aqui o link da NF"
              label="LINK DA NOTA FISCAL"
              value="XXX"
            />

            <div style={{ gridColumn: '1 / 5', margin: '0 auto', display: 'flex' }}>
              <Button clearTemplate customStyles={{ marginRight: '1.6rem' }}>
                Inserir nova nota fiscal
              </Button>

              <Button type="submit">Aplicar</Button>
            </div>
          </CardBox> */}

            <CardBox label="Endereço de entrega" className="box__address">
              <div className="address">
                <strong>
                  {`${data?.shipment?.address}, ${data?.shipment?.address_number}, ${data?.shipment?.complement}`}
                </strong>

                <p>
                  {data?.shipment?.city}/{data?.shipment?.state}
                  <br />
                  CEP: {formatMask(data?.shipment?.zip_code, '#####-###')}
                </p>
              </div>

              <div className="address">
                <strong>Responsável pelo recebimento:</strong>
                <p>{data?.shipment?.responsible_receiving}</p>
              </div>
            </CardBox>

            <CardBox label="Produtos">
              <ul>
                {data?.itens.map(item => (
                  <li key={item.id}>
                    <img src={item.image || iconFood} alt={item.product} />

                    <div>
                      <strong className="margin-bottom">{item.product}</strong>

                      <p>
                        <strong>Qntdd.: </strong>
                        {item.amount} {item.measure_unit?.toLocaleLowerCase()}(s)
                      </p>
                    </div>

                    <div>
                      <p className="margin-bottom">
                        <strong>Lote: </strong>
                        {item.fifo_pack_number || 'não se aplica'}
                      </p>

                      <p>
                        <strong>Validade: </strong>
                        {formatDate(item.fifo_expire_date)}
                      </p>
                    </div>

                    <div>
                      <strong className="margin-bottom">SKU do produto</strong>
                      {item.sku || 'não se aplica'}
                    </div>

                    <div>
                      <strong className="margin-bottom">Código de barras DUN14</strong>
                      {item.dum14_code || 'não se aplica'}
                    </div>

                    <div>
                      <strong className="margin-bottom">Código de barras EAN13</strong>
                      {item.ean13_code || 'não se aplica'}
                    </div>

                    <div className="fex-end">
                      <strong className="margin-bottom">Valor por {item.measure_unit?.toLocaleLowerCase()}</strong>
                      {formatPrice(item.unit_net_value)}
                    </div>
                  </li>
                ))}
              </ul>

              {/* <div className="divider" /> */}

              {/* <Button customStyles={{ margin: '3.2rem auto' }} onClick={() => setOpenModal(true)}>
              Ajustar produtos
            </Button> */}
            </CardBox>
          </main>
        </Container>
      )}
    </>
  )
}

export { OrderDetails }
