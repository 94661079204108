import styled from 'styled-components'

export const ModalContainer = styled.div`
  .box__image {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }

    .box__options {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      max-width: 25rem;
      width: 100%;

      label {
        margin-top: 1.6rem;

        img {
          border-radius: 1.6rem;
          object-fit: cover;
          cursor: pointer;
          height: 20rem;
          width: 20rem;
        }
      }
    }
  }
`

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;

    h1 {
      margin: 0 0 0 1.6rem;
      font-weight: normal;
    }
  }

  form {
    max-width: 51.2rem;
    padding: 6.4rem 0;
    margin: 0 auto;

    .box__logo {
      margin-bottom: 3.2rem;
      position: relative;

      .button__change__logo {
        border-radius: 100%;
        cursor: pointer;
        border: none;
        height: 3rem;
        width: 3rem;

        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      img {
        border-radius: 0.8rem;
        width: 100%;
      }
    }
  }
`
