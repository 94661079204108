import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import dayjs from 'dayjs'

import { UilStar } from '@iconscout/react-unicons'

import { CardBox, Input, Loading, Paginate } from '../../components'
import { routesPrivate } from '../../routes/routesLink'
import { GetReviewsAll } from '../../services/Reviews'
import { formatDate, toastify } from '../../utils'
import { Container } from './styles'

const Reviews: React.FC = () => {
  const { isLoading, isFetched, data, refetch } = useQuery('reviews', fetchData)

  const [params, setParams] = useState({ pageOffset: 10, page: 1 })

  async function fetchData() {
    try {
      return await GetReviewsAll(params)
    } catch (error) {
      toastify('Erro ao carregar as avaliações', 'error')
    }
  }

  function handleChangeState(event: any): void {
    const { value, name, type } = event.target

    if (type === 'date') {
      const date = dayjs(value).toISOString()
      setParams(prevState => ({ ...prevState, page: 1, [name]: value ? new Date(date) : undefined }))
    } else {
      setParams(prevState => ({ ...prevState, page: 1, [name]: value || undefined }))
    }
  }

  function handleKeyDown(event: any): void {
    if (event.key !== 'Enter') return
    refetch()
  }

  const handlePreviousPage = () => setParams(prevState => ({ ...prevState, page: prevState.page - 1 }))
  const handleNextPage = () => setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))

  useEffect(() => {
    refetch()
  }, [params.page])

  return (
    <Container>
      <header>
        <h1>Avaliações</h1>

        <div className="box__search">
          <Input
            placeholder="Data da avaliação"
            onChange={handleChangeState}
            onKeyDown={handleKeyDown}
            name="review_date"
            type="date"
          />

          <Input
            placeholder="Buscar núm. do pedido"
            onChange={handleChangeState}
            onKeyDown={handleKeyDown}
            name="order_number"
            iconSearch
          />
        </div>
      </header>

      <main>
        <div className="title__list">
          <span>Data</span>
          <span>Núm. do pedido</span>
          <span>Avaliação pedido</span>
          <span>Nome varejista</span>
          <span>Avaliação indústria</span>
          <span>Avaliação entrega</span>
          <span>Comentários</span>
        </div>

        {isLoading && <Loading style={{ margin: '0 auto' }} />}

        {isFetched && (
          <>
            <CardBox customStyles={{ padding: 0 }}>
              <ul>
                {data?.results?.map(review => (
                  <li key={review?.review_id}>
                    <span>{formatDate(review?.rated_at)}</span>

                    <span>{review?.order_number}</span>

                    <span>
                      <UilStar />
                      {review?.order_score}
                    </span>

                    <span>{review?.retailer}</span>

                    <span>
                      <UilStar />
                      {review?.industry_score}
                    </span>

                    <span>
                      <UilStar />
                      {review?.shipment_score}
                    </span>

                    <span>{review?.comments}</span>

                    <Link className="link" to={routesPrivate.reviewsDetails + review?.review_id}>
                      ver detalhes
                    </Link>
                  </li>
                ))}
              </ul>
            </CardBox>

            <Paginate
              customStyles={{ position: 'fixed', bottom: '1.6rem' }}
              handlePreviousPage={handlePreviousPage}
              totalPages={data?.total_pages || 1}
              handleNextPage={handleNextPage}
              currentPage={params.page}
            />
          </>
        )}
      </main>
    </Container>
  )
}

export { Reviews }
