import styled from 'styled-components'

export const Container = styled.form`
  h1 {
    font-weight: normal;
    margin: 0;
  }

  header {
    display: flex;
    justify-content: space-between;

    margin-bottom: 3.2rem;

    .header__left {
      display: flex;
      align-items: center;
    }
  }

  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem;

    .images__container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      gap: 1.6rem;

      .box__image {
        position: relative;
        height: 20rem;
        width: 20rem;
        margin: 0;

        button {
          background: var(--color-pink);
          border-radius: 1rem;
          cursor: pointer;
          border: none;
          height: 3rem;
          width: 3rem;

          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
        }

        img {
          border-radius: 1.6rem;
          height: 20rem;
          width: 20rem;
        }
      }

      .box__spam {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        border: dashed 0.1rem var(--color-grey);
        color: var(--color-grey);
        padding: 1.6rem 3.2rem;
        border-radius: 1.6rem;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;

        svg {
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
          padding: 0.8rem;
        }
      }
    }

    .divider {
      grid-column: 1 /3;

      border: dashed 0.1rem var(--color-primaryTwo);
    }
  }
`

export const ModalContainer = styled.div`
  .box__image {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }

    .box__options {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      max-width: 25rem;
      width: 100%;

      label {
        margin-top: 1.6rem;

        img {
          border-radius: 1.6rem;
          object-fit: cover;
          cursor: pointer;
          height: 20rem;
          width: 20rem;
        }
      }
    }
  }
`
