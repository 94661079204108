export const PAYMENT_METHOD = {
  credit_card: 'Cartão de crédito',
  debit_card: 'Cartão de débito',
  bankslip: 'Boleto bancário',
  pix: 'Pix'
} as any

export const PAYMENT_STATUS = {
  refund_trouble: 'Problema de reembolso',
  in_refund: 'Em processo de reembolso',
  pre_authorized: 'Pré autorizado',
  charged_back: 'Cobrado de volta',
  processing: 'Em processamento',
  written_off: 'Amortizado',
  cancelling: 'Cancelando',
  authorized: 'Autorizado',
  disputed: 'Disputado',
  refunded: 'Devolvido',
  released: 'Liberado',
  waiting: 'Esperando',
  refused: 'Recusado'
} as any
