export const routesPublic = {
  forgotPassword: '/forgot-password/',
  login: '/'
}

export const routesPrivate = {
  home: '/',
  catalog: '/catalog/',
  catalogSegments: '/catalog/segments/',
  catalogProducts: '/catalog/products/',
  catalogBrands: '/catalog/brands/',
  dashboard: '/dashboard/',
  financial: '/financial/',
  financialRegisteredAccounts: '/financial/registered-accounts/',
  financialChangeMainAccount: '/financial/change-main-account/',
  financialDepositDetails: '/financial/details/',
  financialHistory: '/financial/history/',
  financialCreate: '/financial/create/',
  orders: '/orders/',
  ordersDetails: '/orders/details/',
  products: '/products/',
  productsCreate: '/products/create/',
  productsEdit: '/products/edit/',
  retailers: '/retailers/',
  retailersOrderHistory: '/retailers/order-history/',
  retailersOrderDetails: '/retailers/order-details/',
  reviews: '/reviews/',
  reviewsDetails: '/reviews/details/',
  settings: '/settings/',
  settingsCommercialOpinion: '/settings/commercial-opinion/',
  settingsRegistrationData: '/settings/registration-data/',
  settingsCommercialData: '/settings/commercial-data/',
  settingsContacts: '/settings/contacts/',
  settingsPassword: '/settings/password/',
  settingsAddress: '/settings/address/'
}
