/* eslint no-case-declarations: "off" */
import axios, { Axios, AxiosError, AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

import { routesPublic } from '../routes/routesLink'
import { logErros, logRequest, logResponse } from '../utils'

import { ILoginResponse } from './Session/interfaces'

interface ITokens {
  refresh_token: string
  token: string
}

let tokens: ITokens | null = null
let fetchingRefreshToken = false

class ApiClient {
  api: Axios

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      responseType: 'json',
      timeout: 60000
    })

      this.api.interceptors.request.use(async request => {
        logRequest(request)
        return await this.refreshToken(request)
      })

      this.api.interceptors.response.use(
        success => {
          logResponse(success)
          return Promise.resolve(success.data)
        },

        async (error: AxiosError) => {
          logErros(error)
          return Promise.reject(error)
        }
      )
  }

  async refreshToken(request: AxiosRequestConfig) {
    if (!tokens) {
      const storageExist = localStorage.getItem('@tokens')
      tokens = storageExist ? JSON.parse(storageExist) : null

      return request
    }

    const { refresh_token: storageRefreshToken, token: storageToken } = tokens

    const decodedToken = jwtDecode(storageToken) as { exp: number }
    const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs(), 'seconds') <= 0

    try {
      if (isExpired && fetchingRefreshToken === false) {
        fetchingRefreshToken = true

        const response = (await this.api.get(`/api/refresh_token/${storageRefreshToken}`)) as ILoginResponse
        const { refresh_token, token, user } = response

        localStorage.setItem('@tokens', JSON.stringify({ refresh_token, token }))
        localStorage.setItem('@user', JSON.stringify(user))

        tokens = { refresh_token, token }
        fetchingRefreshToken = false

        this.setTokenInHeader(token)
      }

      return request
    } catch (error) {
      delete this.api.defaults.headers.common.Authorization

      window.location.href = routesPublic.login
      localStorage.clear()
    }
  }

  setTokenInHeader(token: string): void {
    this.api.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export default new ApiClient()
